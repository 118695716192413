<script>
import {gameStore} from "@/stores/Game.store";
import TeamName from "@/components/common/TeamName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import {sportStore} from "@/stores/Sport.store";
import GameDate from "@/components/common/GameDate.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import axiosService from "@/services/axios.service";
import Statistic from "@/components/common/Statistic.vue";
import {modalStore} from "@/stores/Modal.store";
import SelectionItem from "@/components/common/SelectionItem.vue";
import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "GameModal",
  components: {OfferBookmakerValue, SelectionItem, Statistic, LeagueLogo, GameDate, TeamLogo, TeamName},
  data() {
    return {
      matchSelections: null,
      notMatchSelections: null,

      selectedSelectionUID: null
    }
  },
  computed: {

    _sportStore() {
      return sportStore();
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'gameModal';
    },
    _gameUID() {
      if (!this._isVisible) return null;
      const payload = modalStore().getVisibleModal.gameUID
      if (!payload) return null;
      return payload;
    },
    _game() {
      return gameStore().get(this._gameUID)
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _canAccessGame() {
      return gameStore().canAccessGame(this._gameUID)
    },
    _cannotAccessGameLabel() {
      const code = this._canAccessGame
      switch (code) {
        case 'KO_GAME_NOT_FOUND':
          return 'La rencontre n\'existe pas'
        case 'KO_LEAGUE_NOT_FOUND':
          return 'La compétition n\'existe pas'
        case 'KO_LEAGUE_OUT_OF_SUBSCRIPTION':
          return 'Ton abonnement ne te permet pas d\'accéder à cette rencontre'
        case 'KO_LIMIT_TO_3_DAYS_AHEAD':
          return 'Tu ne peux pas accéder à une rencontre qui a lieu dans plus de 3 jours. Abonne-toi pour y accéder'
        case 'KO_LIMIT_TO_1_DAY_AHEAD':
          return 'Tu ne peux pas accéder à une rencontre qui a lieu dans plus de 1 jour. Connecte-toi pour y accéder'
        default:
          return ''
      }
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _isSelectedSelection(s) {
      if (!s || !this.selectedSelectionUID) return false
      return s.uid === this.selectedSelectionUID
    },
    _setSelectedSelectionUID(uid) {
      if (this.selectedSelectionUID === uid) {
        this.selectedSelectionUID = null
      } else {
        this.selectedSelectionUID = uid
      }
    }
  },
  watch: {
    _gameUID(uid) {
      if (!uid) return;

      this.matchSelections = null;
      this.notMatchSelections = null;

      // On va chercher les seasons auxquelles l'équipe participe
      axiosService.get(`/dta/game/` + uid + `/selections`).then(response => {
        this.matchSelections = response.data.matchSelections;
        this.notMatchSelections = response.data.notMatchSelections;
      })
    },
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="350" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header" v-if="_game"  style="color: black !important;">
      <team-logo :uid="_game.homeTeamUID" size="small"/>
      <team-name :uid="_game.homeTeamUID" style="color: black !important;"/>
      -
      <team-name :uid="_game.awayTeamUID" style="color: black !important;"/>
      <team-logo :uid="_game.awayTeamUID" size="small"/>
    </header>

    <div class="content">

      <div v-if="_canAccessGame !== true">
        <a-alert type="error" show-icon :message="_cannotAccessGameLabel" class="m-2 p-2"/>
      </div>

      <div v-else>
        <fieldset class="no-border m-2">
          <legend>Sélections qui match</legend>
          <a-alert v-if="!matchSelections" type="info" show-icon message="Chargement..."/>
          <a-alert v-else-if="matchSelections.length === 0" type="warning" show-icon
                   message="Il n'y a pas de sélections qui match"/>
          <div v-else v-for="item in matchSelections">
            <selection-item :value="item.selection" :selected="_isSelectedSelection(item.selection)" :show-monitoring="false"
                            @click="_setSelectedSelectionUID(item.selection.uid)" class="mb-1"/>
            <div v-if="_isSelectedSelection(item.selection)">
              <hr>
              <span v-for="(statUIDs,period) in item.statsMatch">
              <statistic :uid="statUID" :period="period" v-for="statUID in statUIDs" :positive="true"/>
            </span>
              <hr>
              <span v-for="(statUIDs,period) in item.statsNotMatch">
              <statistic :uid="statUID" :period="period" v-for="statUID in statUIDs" :positive="false"/>
            </span>
              <span v-for="choice in item.choicesMatch">
              <offer-bookmaker-value :obv="choice" :positive="true"/>
            </span>
              <hr>
              <span v-for="choice in item.choicesNotMatch">
              <offer-bookmaker-value :obv="choice" :positive="false"/>
            </span>
            </div>
          </div>
        </fieldset>
        <hr/>
        <fieldset class="no-border m-2">
          <legend>Autres sélections</legend>
          <a-alert v-if="!notMatchSelections" type="info" show-icon message="Chargement..."/>
          <a-alert v-else-if="notMatchSelections.length === 0" type="warning" show-icon
                   message="Il n'y a pas de sélections qui ne match pas"/>
          <div v-else v-for="item in notMatchSelections">
            <selection-item :value="item.selection"/>
          </div>
        </fieldset>
      </div>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="" @click="_onCancel">Fermer</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>