// stores/counter.js
import {defineStore} from 'pinia'
import {teamStore} from "@/stores/Team.store";
import axiosService from "@/services/axios.service";
import {contextStore} from "@/stores/Context.store";
import dateService from "@/services/date.service";
import {leagueStore} from "@/stores/League.store";

export const gameStore = defineStore('gameStore', {
    state: () => {
        return {
            loadingUIDs: [],
            fetchGameByUIDTimeout: null,
            items: []
        }
    },
    actions: {

        addGames(games, callback) {
            this.items = this.items.concat(games)

            // remove duplicates
            this.items = [...new Set(this.items)]

            const allGamesUIDs = this.items.map(item => item.uid)
            this.loadingUIDs = this.loadingUIDs.filter(uid => allGamesUIDs.indexOf(uid) < 0)

            if (callback) callback()
        },

        fetchByLoadingUIDs() {
            if (this.fetchGameByUIDTimeout) clearTimeout(this.fetchGameByUIDTimeout)
            this.fetchGameByUIDTimeout = setTimeout(() => {

                // remove NaN from loadingUIDs
                this.loadingUIDs = this.loadingUIDs.filter(uid => !isNaN(uid))

                let joinedUIDs = this.loadingUIDs.join(',');
                if (joinedUIDs.length === 0) return
                axiosService.get('/dta/games/' + joinedUIDs).then(response => {
                    this.addGames(response.data.results)
                });

            }, 200)
        },
        fetchGameByUIDs(uids) {
            if (uids.length === 0) {
                return
            }

            uids = uids.filter(uid => this.loadingUIDs.indexOf(uid) < 0)
            if (uids.length === 0) {
                return
            }

            let newUIDs = this.loadingUIDs.concat(uids)

            // remove duplicates
            this.loadingUIDs = [...new Set(newUIDs)]

            gameStore().fetchByLoadingUIDs()
        }
    },
    getters: {

        get: (state) => (uid) => {

            if (isNaN(uid)) return null
            if (uid === 'NaN') return null

            if (state.loadingUIDs.indexOf(uid) >= 0) {
                return null
            }

            uid = parseInt(uid)

            let game = state.items.find(item => parseInt(item.uid) === uid);
            if (game) {
                return game
            }
            state.loadingUIDs.push(uid)

            // Si le match n'est pas dans le store, on le charge
            const gs = gameStore()
            gs.fetchByLoadingUIDs()

            return null
        },

        getHomeTeamUID: (state) => (game) => {
            if (!game) {
                return null
            }
            return game.homeTeamUID
        },

        getAwayTeamUID: (state) => (game) => {
            if (!game) {
                return null
            }
            return game.awayTeamUID
        },

        getArea: (state) => (uid) => {
            const gs = gameStore()
            let game = gs.get(uid);
            if (!game) {
                return null
            }
            return game.area
        },

        getDate: (state) => (uid) => {
            const gs = gameStore()
            let game = gs.get(uid);
            if (!game) {
                return null
            }
            return game.date
        },

        getGameDate: (state) => (game) => {
            if (!game) {
                return null
            }
            return game.date
        },

        getShortLabel: (state) => (uid) => {
            const gs = gameStore()
            let game = gs.get(uid);
            if (!game) {
                return "? - ?"
            }

            const ts = teamStore()
            const homeTeam = ts.get(game.homeTeamUID)
            const awayTeam = ts.get(game.awayTeamUID)

            return homeTeam.shortName + ' - ' + awayTeam.shortName
        },

        getAll: (state) => {
            return state.items
        },

        getTeamLocation: (state) => (gameUID, teamUID) => {
            const game = gameStore().get(gameUID)

            if (!game) {
                return null
            }

            if (game.homeTeamUID === parseInt(teamUID)) return 'HOME'
            if (game.awayTeamUID === parseInt(teamUID)) return 'AWAY'

            return null
        },

        getResultFor: (state) => (game, location) => {
            if (!game) return null

            if (game.scoreFtHome === null || game.scoreFtAway === null) return null

            let diffScore = game.scoreFtHome - game.scoreFtAway
            if (location === 'AWAY') {
                diffScore = 0 - diffScore
            }
            return diffScore
        },

        canAccessGame: (state) => (gameUID) => {

            const game = gameStore().get(gameUID)
            if (!game) {
                return 'KO_GAME_NOT_FOUND'
            }

            // On vérifie que la ligue est gratuite
            const league = leagueStore().get(game.leagueUID)
            if(!league) {
                console.warn('Cannot access game. league not found', game.leagueUID)
                return 'KO_LEAGUE_NOT_FOUND'
            }

            const leagueLicenceRequired = league.licenceRequired
            const userLicence = contextStore().getLicenseType()
            function licenceLevel(licence) {
                switch (licence) {
                    case 'NONE':
                        return 0
                    case 'BASIC':
                        return 1
                    case 'FULL':
                        return 2
                    default:
                        return 0
                }
            }

            if(licenceLevel(leagueLicenceRequired) > licenceLevel(userLicence)) {
                console.warn('Cannot access game. league out of your licence', game.leagueUID)
                return 'KO_LEAGUE_OUT_OF_SUBSCRIPTION'
            }

            const isAuthenticated = contextStore().isAuthenticated()
            // si l'utilisateur est authentifié, il peut accéder à tous les matchs dans les 3 jours a venir
            const dateReference = isAuthenticated ? dateService.addDays(new Date(), 7) : dateService.addDays(new Date(), 1)
            const endDate = dateService.getEndOf(dateReference)
            const can = dateService.isBefore(game.date, endDate)
            if (!can) {
                if (isAuthenticated) {
                    console.warn('Cannot access game. date too far', game.date, endDate)
                    return 'KO_DATE_TOO_FAR'
                } else {
                    console.warn('Cannot access game. date too far', game.date, endDate)
                    return 'KO_LIMIT_TO_1_DAY_AHEAD'
                }
            }

            // Si on est là, c'est que tout est OK
            return true

        }
    }
})