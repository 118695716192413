<script>

import {statStore} from "@/stores/Statistic.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import {modalStore} from "../../stores/Modal.store";
import PlayerPicture from "@/components/common/PlayerPicture.vue";

export default {
  name: "Statistic",
  components: {PlayerPicture, LeagueLogo, TeamLogo},
  props: {
    uid: {
      type: Number,
      required: true
    },
    period: {
      type: String,
      required: true
    },
    positive: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    _statStore() {
      return statStore();
    },
    _stat() {
      return this._statStore.get(this.uid)
    },
    _statLabel() {
      return this._statStore.getLabel(this._stat, this.period)
    },
    _statValue() {
      return this._statStore.getValue(this._stat, this.period)
    },
    _buildCssClasses() {
      return {
        'statistic flex': true,
        'positive': this.positive,
        'negative': !this.positive
      }
    },
    _locationLabel() {
      if (!this._stat) return null;
      switch (this._stat.location) {
        case 'HOME':
          return 'Dom'
        case 'AWAY':
          return 'Ext'
        case 'ANY':
          return 'D&E'
        default:
          return '?'
      }
    }
  },

  methods: {
    _openStatModal(event) {
      event.stopPropagation()
      modalStore().openModal({
        uid: 'statisticModal', payload: {
          'uid': this.uid,
          'period': this.period
        }
      })
    }
  }
}
</script>

<template>
  <div :class="_buildCssClasses" @click="_openStatModal" v-if="_stat">
    <div class="value pl-2">
      {{ _statValue }}
    </div>
    <team-logo :uid="_stat.whoUID" v-if="_stat.whoUID" class="ml-2" size="small"/>
    <span v-if="_stat.againstUID" class="ml-1">VS</span>
    <team-logo v-if="_stat.againstUID" :uid="_stat.againstUID" class="ml-1" size="small" style="display: inline-block"/>
    <league-logo :uid="_stat.leagueUID" class="ml-2"/>
    <div class="ml-2">{{ _locationLabel }}</div>
    <player-picture :uid="_stat.playerUID" v-if="_stat.playerUID" class="ml-2"/>
    <div class="label ml-2 text-left py-1" style="line-height: normal">
      {{ _statLabel }}
    </div>
  </div>
  <div v-else>
    Loading...
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.statistic {
  color: white;
  font-size: @font-size-small;
  min-height: 24px;
  align-items: center;

  &:hover {
    background: @hover-color;
  }

  &.positive {
    background: @positive-color;
    background: linear-gradient(131deg, fade(@positive-color, 60%) 0%, fade(@positive-color, 30%) 20%, rgba(0,0,0,0) 33%);

    .value{

      font-weight: bold;
    }

  }

  &.negative {
    background: @negative-color;
    background: linear-gradient(131deg, fade(@negative-color, 60%) 0%, fade(@negative-color, 30%) 20%, rgba(0,0,0,0) 33%);

  }


  .value {
    font-weight: bold;
    text-align: right;
    min-width: 32px;
    display: flex;
    align-items: center;
  }
}
</style>