<script>
import MainHeader from "@/components/common/MainHeader.vue";
import UserPreferencesModal from "@/components/common/modal/user_preferences/UserPreferencesModal.vue";
import StatisticModal from "@/components/common/modal/statistic/StatisticModal.vue";
import GameModal from "@/components/common/modal/game/GameModal.vue";
import SearchModal from "@/components/common/modal/search/SearchModal.vue";
import TeamModal from "@/components/common/modal/team/TeamModal.vue";
import LeagueModal from "@/components/common/modal/league/LeagueModal.vue";
import BankrollPane from "./panes/bankroll/BankrollPane.vue";
import BankrollsModal from "./modals/BankrollsModal.vue";
import CreateBankrollModal from "./modals/CreateBankrollModal.vue";
import CreateTicketModal from "./modals/CreateTicketModal.vue";
import EditBankrollModal from "@/components/routes/bankroll/modals/EditBankrollModal.vue";
import WelcomeBankrollPane from "@/components/routes/bankroll/panes/welcome/WelcomeBankrollPane.vue";
import AnalyseBankrollPane from "@/components/routes/bankroll/panes/analyse/AnalyseBankrollPane.vue";
import CalculatorModal from "@/components/common/modal/calculator/CalculatorModal.vue";
import PaypalModal from "@/components/common/modal/paypal/PaypalModal.vue";

// Stores
import {contextStore} from "@/stores/Context.store";
import {bankrollStore} from "@/stores/Bankroll.store";
import {bankrollPaneStore} from "@/components/routes/bankroll/panes/bankroll/BankrollPane.store";

export default {
  name: "BankrollRoute",
  components: {
    PaypalModal,
    CalculatorModal,
    AnalyseBankrollPane,
    WelcomeBankrollPane,
    EditBankrollModal,
    CreateTicketModal,
    CreateBankrollModal,
    BankrollsModal,
    BankrollPane,
    LeagueModal,
    TeamModal,
    SearchModal,
    GameModal,
    StatisticModal,
    UserPreferencesModal,
    MainHeader
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _urlUID() {
      // On recupere l'uid de l'url
      return this.$route.query.uid
    },
    _selectedBankrollUID() {
      return bankrollPaneStore().getBankrollActiveUID()
    }
  },
  beforeMount() {
    if (!contextStore().isInitialized()) {
      this.$router.push({name: 'init', query: {redirect: 'bankrolls', query: this._urlUID}})
    }

    // On recupere la bankroll par son uid, si elle n'est pas deja en memoire on la recupere
    bankrollStore().get(this._urlUID)
  },
  mounted() {
    // On met a jour le nom de la route courante
    contextStore().setRoute('bankrolls')
    setTimeout(() => {
      if (this._urlUID) {
        bankrollPaneStore().setBankrollActiveUID(this._urlUID)
      }
    }, 250)
  }
}
</script>

<template>
  <div :class="{'mobile': _isMobile}">
    <main-header/>
    <div class="flex">
      <bankroll-pane/>
      <analyse-bankroll-pane v-if="_selectedBankrollUID"/>
      <welcome-bankroll-pane v-else/>
    </div>

    <user-preferences-modal/>
    <game-modal/>
    <team-modal/>
    <league-modal/>
    <search-modal/>
    <bankrolls-modal/>
    <create-bankroll-modal/>
    <create-ticket-modal/>
    <edit-bankroll-modal/>
    <calculator-modal/>
    <paypal-modal/>
  </div>
</template>

<style scoped lang="less">

</style>