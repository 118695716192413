<script>
import TeamName from "@/components/common/TeamName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import LeagueName from "@/components/common/LeagueName.vue";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import GameScore from "@/components/common/GameScore.vue";
import GameDate from "@/components/common/GameDate.vue";
import {gameStore} from "@/stores/Game.store";

export default {
  name: "TeamModalGameRow",
  components: {GameDate, GameScore, GameResultCircle, LeagueName, TeamLogo, TeamName},

  props: {
    teamUID: {
      type: Number,
      required: true
    },
    gameUID: {
      type: Number,
      required: true
    }
  },
  computed: {
    _game(){
      if(!this.gameUID) return null
      return gameStore().get(this.gameUID)
    },
    _homeTeamUID() {
      if(!this._game) return null
      return this._game.homeTeamUID
    },
    _awayTeamUID() {
      if(!this._game) return null
      return this._game.awayTeamUID
    },
    _adversaireTeamUID() {
      if(this._homeTeamUID === this.teamUID) return this._awayTeamUID
      return this._homeTeamUID
    },
  },
  methods: {

  }
}
</script>

<template>
  <tr class="p-2">
    <td>
      <game-date :uid="gameUID" style="min-width: 44px"/>
    </td>
    <td>
      <game-result-circle :team-uid="teamUID" :game-uid="gameUID"/>
    </td>

    <td class="text-center">
      <game-score :uid="gameUID"/>
    </td>
    <td class="text-center">
      <team-logo :uid="_adversaireTeamUID"/>
    </td>
    <td class="text-left">
      <team-name :uid="_adversaireTeamUID"/>
    </td>


  </tr>
</template>

<style scoped lang="less">
</style>