<script>

import {analysePaneStore} from "@/components/routes/selection/panes/analyse/AnalysePane.store";

import AnalysePaneTab from "@/components/routes/selection/panes/analyse/header/tab/AnalysePaneHeaderTab.vue";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "AnalysePaneHeader",
  components: {AnalysePaneTab},
  computed: {
    _gameUIDs() {
      return analysePaneStore().getGameUIDsBySport(contextStore().getSport)
    },
  },
  methods: {}
}
</script>

<template>
  <header id="analyse-pane-header">
    <div class="flex">
      <analyse-pane-tab v-for="gameUID in _gameUIDs" :gameUID="gameUID"/>
    </div>
  </header>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

#analyse-pane-header {
}
</style>