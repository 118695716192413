<script>
import axiosService from "@/services/axios.service";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import {contextStore} from "@/stores/Context.store";
import {teamStore} from "@/stores/Team.store";
import {preferencesStore} from "@/stores/Preferences.store";
import {leagueStore} from "@/stores/League.store";

export default {
  name: "SelectionPaneItem.Forme.Tab",
  components: {TeamLogo, GameResultCircle},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      data: null,
      chart: {
        key: 0,
        type: 'area',
        width: (contextStore().isMobileDevice() ? contextStore().getScreenWidth() : 400) - 32,
        options: {
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
            border: {
              show: false,
            },
          },
          colors: ['#169272', '#516dd8'],
          dataLabels: {
            enabled: false,
          },
        },
        series: [],
      },

      filters: {
        type: 'ALL'
      },
    }
  },
  beforeMount() {


    // On va chercher la préférence du type
    let type = preferencesStore().getFirstValue('SELECTION_FORME_TAB', 'TYPE');
    if (type) {
      this.filters.type = type
    } else {
      preferencesStore().save('SELECTION_FORME_TAB', 'TYPE', [this.filters.type])
    }

    this._refreshData()
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid)
    },
    _homeGames() {
      if (!this.data) return []
      if (!this.data.home) return []
      if (!this.data.home.gameUIDs) return []

      const games = []
      this.data.home.gameUIDs.forEach(uid => {
        let game = gameStore().get(uid);
        if (!game) return
        games.push(game)
      })
      return games.sort((b, a) => {
        if (a.date === b.date) return 0
        if (a.date < b.date) return 1
        if (a.date > b.date) return -1
      })
    },
    _awayGames() {
      if (!this.data) return []
      if (!this.data.away) return []
      if (!this.data.away.gameUIDs) return []

      const games = []
      this.data.away.gameUIDs.forEach(uid => {
        let game = gameStore().get(uid);
        if (!game) return
        games.push(game)
      })
      return games.sort((b, a) => {
        if (a.date === b.date) return 0
        if (a.date < b.date) return 1
        if (a.date > b.date) return -1
      })
    }
  },

  methods: {
    _diff(gameUID, teamUID) {
      const game = gameStore().get(gameUID)
      if (!game) return null
      if (!(game.scoreFtHome >= 0) || !(game.scoreFtAway >= 0)) return null
      const diff = game.homeTeamUID === teamUID ? game.scoreFtHome - game.scoreFtAway : game.scoreFtAway - game.scoreFtHome

      if (diff === 0) {
        return ""
      }
      if (diff > 0) {
        return "+" + diff
      } else {
        return diff
      }
    },

    _setType(type) {
      this.filters.type = type
      preferencesStore().save('SELECTION_FORME_TAB', 'TYPE', [this.filters.type])

      this._refreshData()
    },

    _refreshData() {
      const payload = {
        uid: this.gameUid,
        locationStrict: this.filters.type === 'DOM_EXT'
      }
      axiosService.put('/dta/game/basic-data/forme', payload).then(response => {
        this.data = response.data

        setTimeout(() => {
          this._updateChart()
        }, 1000)

      })
    },

    _updateChart() {
      if(!this._game) return

      this.chart.series = []
      const ts = teamStore()
      const gs = gameStore()

      let teamUIDs = [this._game.homeTeamUID, this._game.awayTeamUID]
      let firstTeamUIDLocation = gs.getTeamLocation(contextStore().gameUID, teamUIDs[0])
      if (firstTeamUIDLocation === 'AWAY') {
        teamUIDs.reverse()
      }
      teamUIDs.forEach((teamUID) => {
        const team = ts.get(teamUID)
        const teamSerie = {
          name: team ? team.name : teamUID.toString(),
          data: []
        }

        let teamGames = (teamUID === this._game.homeTeamUID) ? this._homeGames : this._awayGames
        let index = 0
        while (index + 5 <= teamGames.length) {
          let subList = teamGames.slice(index, index + 5)
          let currentDataValueComposantes = []
          subList.forEach(game => {
            let location = gs.getTeamLocation(game.uid, teamUID)
            let gameResultForTeam = gs.getResultFor(game, location)

            if (null !== gameResultForTeam) {
              if (gameResultForTeam === 0) {  // Nul
                currentDataValueComposantes.push(-0.25)
              } else if (gameResultForTeam > 0) { // Victoire
                currentDataValueComposantes.push(1)
              } else if (gameResultForTeam < 0) {
                currentDataValueComposantes.push(-0.5)
              }
            }
          })

          if (currentDataValueComposantes.length === 5) {
            // on additionne les composantes
            let sum = 0
            currentDataValueComposantes.forEach(composante => {
              sum += composante
            })
            teamSerie.data.push(sum.toFixed(1))
          }
          index++
        }

        this.chart.series.push(teamSerie)
      })

      // On va egaliser le nombre de valeur pour chaque équipe en retirant les premieres valeurs à l'équipe qui a trop de valeurs
      let teamASerie = this.chart.series[0]
      let teamBSerie = this.chart.series[1]
      if (teamASerie.data.length > teamBSerie.data.length) {
        let diff = teamASerie.data.length - teamBSerie.data.length
        teamASerie.data.splice(0, diff)
      } else if (teamBSerie.data.length > teamASerie.data.length) {
        let diff = teamBSerie.data.length - teamASerie.data.length
        teamBSerie.data.splice(0, diff)
      }
      this.chart.series = [teamASerie, teamBSerie]

      this.chart.key++
    },

    _isCurrentSeason(game) {
      if(!game) return false
      const league = leagueStore().get(game.leagueUID)
      if(!league) return false
      return game.seasonName === league.currentSeason
    }
  },
}
</script>

<template>
  <div>
    <div class="flex">
      <fieldset class="m-1 p-0">
        <legend class="m-0">Type</legend>
        <button :class="{'small no-radius' : true, 'primary selected' : filters.type === 'ALL'}"
                style="height: 22.3px"
                @click="_setType('ALL')">Tous
        </button>

        <button :class="{'small no-radius' : true, 'primary selected' : filters.type === 'DOM_EXT'}"
                style="height: 22.3px"
                @click="_setType('DOM_EXT')">D/E
        </button>
      </fieldset>
    </div>
    <table v-if="data">
      <tr>
        <th>

        </th>
        <th colspan="5" class="sr">
          L10
        </th>
        <th colspan="5">
          L5
        </th>
      </tr>
      <tr style="height: 50px">
        <td>
          <team-logo :uid="_game.homeTeamUID"/>
        </td>
        <td v-for="game in _homeGames" :class="{'previous-season' : !_isCurrentSeason(game)}">
          <div style="position: absolute; top:4px;">
            {{ _diff(game.uid, _game.homeTeamUID) }}
          </div>
          <game-result-circle :game-uid="game.uid" :team-uid="_game.homeTeamUID" class="mt-2"/>
        </td>
      </tr>
      <tr style="height: 50px">
        <td>
          <team-logo :uid="_game.awayTeamUID"/>
        </td>
        <td v-for="game in _awayGames" :class="{'previous-season' : !_isCurrentSeason(game)}">
          <div style="position: absolute; top:4px;">
            {{ _diff(game.uid, _game.awayTeamUID) }}
          </div>
          <game-result-circle :game-uid="game.uid" :team-uid="_game.awayTeamUID" class="mt-2"/>
        </td>
      </tr>
    </table>
    <hr/>
    <apexchart
        :key="chart.key"
        ref="teams-forme-evolution-chart-widget"
        :width="chart.width"
        :type="chart.type"
        :options="chart.options"
        :series="chart.series"
        class="mt-2"/>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: @text-color;
  font-size: @font-size-small;

  tr {
    border-bottom: 1px solid @stroke-color;

    &:last-child {
      border-bottom: none;
    }

    th, td {
      padding: @padding-small;
      text-align: center;
      font-family: 'SourceCodePro', serif;
      font-weight: 400;
    }

    th {
      background-color: @background-color-light;
    }

    td {
      &.label {
        text-orientation: mixed;
        writing-mode: vertical-rl;

        &.ll {
          border-left: 1px solid @stroke-color;
        }
      }
    }

    .previous-season {
      opacity: 0.33;
    }
  }
}
</style>