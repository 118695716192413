<script>
import SelectionPane from "@/components/routes/selection/panes/selection/SelectionPane.vue";
import AnalysePane from "@/components/routes/selection/panes/analyse/AnalysePane.vue";
import SelectionOperation from "@/components/routes/selection/panes/selection/condition/SelectionOperation.vue";
import {selectionStore} from "@/stores/Selection.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import {contextStore} from "@/stores/Context.store";
import {userStore} from "@/stores/User.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {sportStore} from "@/stores/Sport.store";
import SelectionFavIndicator from "@/components/common/SelectionFavIndicator.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import {modalStore} from "@/stores/Modal.store";
import UserDropdownMenu from "../../../common/UserDropdownMenu.vue";
import SelectionItem from "@/components/common/SelectionItem.vue";
import {message} from "ant-design-vue";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";
import axiosService from "@/services/axios.service";
import {monitoringStore} from "@/stores/Monitoring.store";
import {bankrollStore} from "@/stores/Bankroll.store";

export default {
  name: "SelectionsModal",
  components: {
    SelectionItem,
    UserDropdownMenu,
    SelectionFavIndicator,
    FontAwesomeIcon,
    SelectionOperation,
    AnalysePane,
    SelectionPane
  },

  data() {
    return {
      tab: 'user', // user or best

      bestSelections: null
    }
  },

  computed: {
    _contextStore() {
      return contextStore();
    },
    _sportStore() {
      return sportStore()
    },
    _selectionStore() {
      return selectionStore();
    },
    _selectedSelectionUID() {
      return selectionPaneStore().selectionActiveUID
    },
    _isSelectionSelected() {
      return this._selectedSelectionUID !== null
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false
      return visibleModal.uid === 'selectionsModal';
    },

    _favoriteSelections() {
      let results = preferencesStore().get('SELECTION', 'FAVORITE_SELECTIONS_' + this._selectedSport);
      if (!results) return [];
      return results.values.map(r => parseInt(r))
    },

    _userSelections() {
      let allUserSelections = [...this._selectionStore.getUserSelections];
      if (!allUserSelections) return [];

      // filter by sport
      if (this._selectedSport) {
        allUserSelections = allUserSelections.filter(s => s.sport === this._selectedSport)
      }
      // sort by favorite status
      allUserSelections.sort((a, b) => {
        if (this._favoriteSelections.includes(a.uid) && !this._favoriteSelections.includes(b.uid)) {
          return -1
        }
        if (!this._favoriteSelections.includes(a.uid) && this._favoriteSelections.includes(b.uid)) {
          return 1
        }
        return 0
      })

      return allUserSelections
    },
    _defaultSelections() {
      let allDefaultSelections = [...this._selectionStore.getDefaultSelections];
      if (!allDefaultSelections) return [];

      // filter by sport
      if (this._selectedSport) {
        allDefaultSelections = allDefaultSelections.filter(s => s.sport === this._selectedSport)
      }
      // sort by favorite status
      allDefaultSelections.sort((a, b) => {
        if (this._favoriteSelections.includes(a.uid) && !this._favoriteSelections.includes(b.uid)) {
          return -1
        }
        if (!this._favoriteSelections.includes(a.uid) && this._favoriteSelections.includes(b.uid)) {
          return 1
        }
        return 0
      })

      return allDefaultSelections
    },
    _sortedBestSelections() {
      // Sort selections by ROI
      if (!this.bestSelections) return []
      return this.bestSelections
    },
    _selectedSport() {
      return this._contextStore.getSport
    },
    _isAuthenticated() {
      return this._contextStore.isAuthenticated()
    },
    _isMobile() {
      return this._contextStore.isMobileDevice()
    }
  },
  methods: {
    _onSelectSelection(s) {
      this._contextStore.setSport(s.sport);
      selectionPaneStore().setSelectionActiveUID(s.uid, s.sport);

      // Si une offre cible est définie dans la selection,
      // on remet à zéro les arguments de l'offre pour utiliser ceux de la sélection.
      if (s.offerFamilyFixedUID) {
        selectionPaneStore().setOfferFamilyFixedUID(null)
        selectionPaneStore().setOfferArgument(null)
        selectionPaneStore().setOfferFamilyFixedUID(s.offerFamilyFixedUID)
        selectionPaneStore().setOfferArgument(s.offerArgument)
      }

      if (s.leagueGroupUID) {
        const leagueGroup = leagueGroupsStore().get(s.leagueGroupUID)
        if (leagueGroup) {
          selectionPaneStore().setSelectedLeagueLabel(leagueGroup.name);
          selectionPaneStore().setSelectedLeagueUIDs(leagueGroup.values);
        }
      } else {
        selectionPaneStore().setSelectedLeagueLabel(null);
        selectionPaneStore().setSelectedLeagueUIDs([]);
      }

      selectionPaneStore().runSelection()
      this._onCancel()
    },

    _openCreateSelectionModal() {

      if (!this._isAuthenticated) {
        message.warning("Connectes toi pour créer une sélection.");
        return;
      }

      modalStore().openModal({
        uid: "createSelectionModal",
        payload: {
          sport: this._selectedSport
        }
      })
    },

    _onCancel() {
      modalStore().closeModal()
    },

    _isSelected(selection) {
      if (!this._selectedSelectionUID) return false;
      if (!selection) return false;
      return this._selectedSelectionUID === selection.uid
    },

    _signWithGoogle() {
      userStore().signInWithGoogle()
    },

    async _requestBestSelections() {
      this.bestSelections = null
      // On charge les meilleures sélections
      const criteria = {
        minROI: -0.025,
        minWinRate: 0.00,
        minTickets: 100,
        orderBy: "roi"
      }
      axiosService.put('/sel/best', criteria).then(response => {
        this.bestSelections = response.data.selections

        monitoringStore().addAll(response.data.monitorings)
        bankrollStore().addItems(response.data.bankrolls)
      }).catch(error => {
        console.error('Error while loading best selections', error)
      })
    }
  },
  watch: {
    tab() {
      if (!this.bestSelections) {
        this._requestBestSelections()
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="700" @cancel="_onCancel" :closable="this._isSelectionSelected"
          :class="{'mobile': _isMobile}">
    <header class="ant-modal-header flex">
      Sélections
    </header>

    <div class="flex">
      <button class="no-radius tab" :class="{'selected': tab === 'user'}" @click="tab='user'">Tes sélections</button>
      <button class="no-radius tab" :class="{'selected': tab === 'best'}" @click="tab='best'">Top sélections</button>
    </div>
    <hr/>
    <div class="content">
      <div v-if="tab==='user'">
        <div class="selection-item-wrapper" v-if="_userSelections.length > 0">
          <selection-item :value="s" :selected="_isSelected(s)" v-for="s in _userSelections"
                          @click="_onSelectSelection(s)"/>
        </div>
        <div class="m-2" v-if="_userSelections.length === 0 && tab==='user'">
          <button class="accent w-full mb-2" @click="_openCreateSelectionModal">Crée ta 1ère sélection personnalisée
          </button>
          <a-alert class="text-center p-4"
                   message="En créant tes propres sélections, tu peux rechercher de manière précise les rencontres succeptibles de t'intéresser."
                   type="info"/>
        </div>
        <h2 class="p-2 mb-5">Pré-définie</h2>
        <div class="selection-item-wrapper">
          <selection-item :value="s" :selected="_isSelected(s)" v-for="s in _defaultSelections"
                          @click="_onSelectSelection(s)"/>
        </div>
      </div>
      <div v-if="tab==='best'">
        <div class="m-2" v-if="!bestSelections">
          <a-spin/>
        </div>
        <div class="selection-item-wrapper">
        <selection-item :value="s" :selected="_isSelected(s)" v-for="s in _sortedBestSelections"
                        @click="_onSelectSelection(s)"/>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex p-1">
        <div class="flex-grow"/>
        <button class="m-1 accent" @click="_openCreateSelectionModal" v-if="_contextStore.isAuthenticated()">Nouvelle</button>
        <a-tooltip placement="left" title="Connexion">
          <button class="primary m-1" v-if="!_isAuthenticated" @click="_signWithGoogle()">
            Connexion
          </button>
        </a-tooltip>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

@source-height: 28px;

#selection-modal-header-search-button {
  border: none !important;
  height: @toolbar-regular;
  padding: @padding-regular !important;
  flex-grow: 1;
  font-size: @font-size-regular !important;
  text-align: left;

  &:focus {
    outline: none;
  }
}

.ant-modal.mobile .content {
  max-height: calc(100dvh - 40px - 40px - 8px - 8px - @source-height) !important;
}

.selection-item-wrapper {
  height: calc(100vh - @toolbar-regular * 2 - @toolbar-small);
  gap: @padding-regular; /* Adjust the gap as needed */
  display: flex;
  flex-wrap: wrap;
  padding: @padding-regular;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}
</style>