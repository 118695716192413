<script>
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import SelectionPaneItemLine1 from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.Line1.vue";
import SelectionPaneItemLineGameData
  from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.LineGameData.vue";
import SelectionPaneItemTabs from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.Tabs.vue";
import {gameStore} from "@/stores/Game.store";
import {notebookStore} from "@/stores/NotebookStore";

export default {
  name: "GameDetailsModal",
  components: {
    SelectionPaneItemTabs,
    SelectionPaneItemLineGameData,
    SelectionPaneItemLine1
  },
  data() {
    return {}
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice();
    },
    _modal() {
      let modal = modalStore().getVisibleModal;
      if (!modal) return null;
      return modal;
    },
    _isVisible() {
      if (!this._modal) return false;
      return this._modal.uid === 'gameDetailsModal';
    },
    _payload() {
      if (!this._modal) return null;
      const payload = this._modal.payload
      if (!payload) return null;
      return payload;
    },
    _gameUID() {
      if (!this._payload) return null;
      return this._payload.gameUID;
    },
    _canAccessGame() {
      return gameStore().canAccessGame(this._gameUID)
    },
    _cannotAccessGameLabel() {
      const code = this._canAccessGame
      switch (code) {
        case 'KO_GAME_NOT_FOUND':
          return 'La rencontre n\'existe pas'
        case 'KO_LEAGUE_NOT_FOUND':
          return 'La compétition n\'existe pas'
        case 'KO_LEAGUE_OUT_OF_SUBSCRIPTION':
          return 'Ton abonnement ne te permet pas d\'accéder aux détails de cette rencontre'
        case 'KO_LIMIT_TO_3_DAYS_AHEAD':
          return 'Tu ne peux pas accéder à une rencontre qui a lieu dans plus de 3 jours'
        default:
          return 'Erreur inconnue'
      }
    },
    _isInNotebook() {
      return notebookStore().isIn(this._gameUID)
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _addToBookMedical() {
      notebookStore().switch(this._gameUID)
    }
  },
  watch: {
    _gameUID(uid) {
      if (!uid) return;

      // TODO
    },
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="420" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Détails de la rencontre
    </header>

    <div class="content">
      <selection-pane-item-line1 :game-uid="_gameUID"/>
      <selection-pane-item-line-game-data :game-uid="_gameUID"/>
      <selection-pane-item-tabs :game-uid="_gameUID" v-if="_canAccessGame === true"/>
      <a-alert type="error" show-icon :message="_cannotAccessGameLabel" class="m-2 p-2" v-else/>
    </div>

    <template #footer>
      <div class="flex my-2 mx-1">

        <div class="flex-grow"/>
        <button :class="{'primary' : !_isInNotebook, 'secondary': _isInNotebook}" @click="_addToBookMedical">
          <span v-if="_isInNotebook">Retirer du Notebook</span>
          <span v-else>Ajouter au Notebook</span>
          <font-awesome-icon icon="fa-solid fa-eyedropper" class="ml-3"/>
        </button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>