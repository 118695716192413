<script>
import axiosService from "@/services/axios.service";
import {gameStore} from "@/stores/Game.store";
import {statStore} from "@/stores/Statistic.store";

import PlayerName from "../../../../../../common/PlayerName.vue";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import PlayerPicture from "@/components/common/PlayerPicture.vue";
import ScorerRow from "@/components/routes/selection/panes/selection/items/tabs/ScorerRow.vue";

export default {
  name: "SelectionPaneItem.Scorers.Tab",
  components: {ScorerRow, PlayerPicture, PlayerName},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {

      filters: {
        type: 'ALL'
      },

      data: null
    }
  },

  beforeMount() {
    if (!this.gameUid) return
    const payload = {
      uid: this.gameUid,
      leagueUIDs: selectionPaneStore().getSelectedLeagueUIDs(),
    }
    axiosService.put('/dta/game/basic-data/scorers', payload).then(response => {
      this.data = response.data
    })
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid)
    },
    _dataValid() {
      return this.data && this.data.results && this.data.results.length > 0
    },
    _orderedPlayersStats() {
      if (!this.data) return []
      const all = this.data.results
      const filterValue = this.filters.type === 'ALL' ? 'ANY' : 'HOME'
      const filteredStats = all.filter(stat => stat.location === filterValue)

      // Order by absolute value
      let ss = statStore();
      return filteredStats.sort((a, b) => {
        const onAbs = ss.getAbsoluteValue(b, 'CURRENT_SEASON') - ss.getAbsoluteValue(a, 'CURRENT_SEASON');
        if (onAbs !== 0) return onAbs
        return ss.getValue(b, 'CURRENT_SEASON') - ss.getValue(a, 'CURRENT_SEASON')
      })
    },
    _filteredOrderedPlayersStats() {
      // On ne garde que les 4 meilleurs de chaque équipe
      let home = this._orderedPlayersStats.filter(stat => this._teamSide(stat.whoUID) === 'HOME').slice(0, 5)
      let away = this._orderedPlayersStats.filter(stat => this._teamSide(stat.whoUID) === 'AWAY').slice(0, 5)

      let keepStatUIDs = home.map(stat => stat.uid).concat(away.map(stat => stat.uid))
      // remove not in home or away
      return this._orderedPlayersStats.filter(stat => keepStatUIDs.includes(stat.uid))
    }
  },

  methods: {
    _teamSide(teamUID) {
      if (!this._game) return null
      return this._game.homeTeamUID === teamUID ? 'HOME' : 'AWAY'
    }
  }
}
</script>

<template>
  <div>
    <table v-if="_dataValid">
      <tr>
        <th colspan="3"></th>
        <th>Tot.</th>
        <th>Moy.</th>
      </tr>
      <scorer-row v-for="item in _filteredOrderedPlayersStats" :stat="item" :side="_teamSide(item.whoUID)"/>
    </table>
    <a-alert v-else-if="!data" message="Chargement..." type="info" class="m-2"/>
    <a-alert v-else type="error" class="m-2" show-icon message="Les données disponibles ne permettent pas de proposer des buteurs potentiels."/>

  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";
</style>