<script>

import {offerStore} from "@/stores/Offer.store";
import {contextStore} from "@/stores/Context.store";
import {bookmakerStore} from "@/stores/Bookmaker.store";
import {modalStore} from "../../stores/Modal.store";

export default {
  name: "OfferChoiceButton",
  props: {
    offerFamily: {
      type: Object,
      required: true
    },
    choice: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _offerStore() {
      return offerStore()
    },
    _contextStore() {
      return contextStore()
    },
    _choiceOffers() {
      return this.choice.offers
    },
    _offerValue() {
      let selectedBookmakerOffer = this._offerStore.getOfferByBookmakerUID(this._choiceOffers, this._contextStore.bookmakerUID);
      if (selectedBookmakerOffer) {
        return selectedBookmakerOffer
      }
      return this._choiceOffers[0]
    },
    _offerBookmakerUID() {
      return this._offerValue.bookmakerUID
    },
    _offerValueValue() {
      return this._offerStore.getOfferValue(this._offerValue)
    },
    _offerValueValuableIndicator() {
      return this._offerStore.getOfferValuableIndicator(this._offerValue)
    },
    _offerRecommendedOdd(){
      return this._offerStore.getOfferRecommendedOdd(this._offerValue)
    },
    _offerValueValuableIndicatorLabel() {
      let value = null
      let positiveChar = null
      if(offerStore().valueIndicatorType === 'indicator'){
        value = this._offerValueValuableIndicator
        positiveChar = '+'
      }else{
        value = this._offerRecommendedOdd
        positiveChar = ''
      }

      if (!value) return '-'
      return (value > 0 ? positiveChar : '') + value

    },
    _offerValueProbability() {
      return this._offerStore.getOfferProbability(this._offerValue)
    },
    _offerValueProbabilityLabel() {
      if (!this._offerValueProbability) return '-'
      return this._offerValueProbability > 0 ? (this._offerValueProbability * 100.0).toFixed(0) + '%' : '-'
    },
    _buildCssStyle() {
      return {
        fontStyle: this._offerBookmakerUID === this._contextStore.bookmakerUID ? '' : 'italic !important',
      }
    },
    _tooltipTitle() {
      const bookmakerName = bookmakerStore().get(this._offerBookmakerUID).name
      return this.offerFamily.label + " / " + this.choice.label + " @ " + bookmakerName
    }
  },
  methods: {
    _onClick(event) {
      event.stopPropagation()
      if (!this.choice) return
      modalStore().openModal({
        uid: 'offerModal',
        payload: {
          offerUID: this._offerValue.offerUID,
          obv: this._offerValue
        }
      })
    }
  },
}
</script>

<template>
  <a-tooltip :title="_tooltipTitle">
    <button :class="{'offer-choice-button': true, 'selected' : selected}" :style="_buildCssStyle" @click.prevent="_onClick">
      <label :class="{'valuable-indicator': true, 'positive' : _offerValueValuableIndicator > 0}">
        {{ _offerValueValuableIndicatorLabel }}
      </label>
      <label class="value px-1">
          {{ _offerValueValue }}
      </label>
      <label :class="{'probability': true}">
        {{ _offerValueProbabilityLabel }}
      </label>
    </button>
  </a-tooltip>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";

.offer-choice-button{
  height: 36px;
  padding: 0 !important;
  margin: 0 4px !important;

  &.selected{
    background: fade(@selected-color, 30%) !important;
  }

  label{
    display: block;
    min-height: 12px;
    max-height: 12px;
    line-height: 12px !important;

    &.valuable-indicator {
      font-size: 10px;
      text-align: center;
      width: 100%;
      padding: 0;
      margin: 0;
      color: @negative-color !important;

      &.positive {
        color: @positive-color !important;
      }
    }

    &.value{
      font-size: 13px;
    }

    &.probability {
      height: 10px;
      font-size: 10px;
      text-align: center;
      width: 100%;
      padding: 0;
      margin: 0;
      color: @text-color;
    }
  }



}




</style>