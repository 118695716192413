<script>

import Statistic from "@/components/common/Statistic.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameHour from "@/components/common/GameHour.vue";
import GameDate from "@/components/common/GameDate.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import {gameStore} from "@/stores/Game.store";
import {contextStore} from "@/stores/Context.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";

export default {
  name: "SelectionPaneItemLine1",
  components: {
    FontAwesomeIcon,
    OfferChoiceButton,
    TeamLogo,
    GameDate,
    GameHour,
    AreaFlag,
    TeamName,
    Statistic,
  },
  props: {
    gameUid: {
      type: Number,
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {
    _gameStore() {
      return gameStore();
    },
    _contextStore() {
      return contextStore();
    },
    _game() {
      if (!this.gameUid) return null
      return this._gameStore.get(this.gameUid)
    },
    _getGameHomeTeamUID() {
      if (!this._game) return null
      return this._gameStore.getHomeTeamUID(this._game)
    },
    _getGameAwayTeamUID() {
      if (!this._game) return null
      return this._gameStore.getAwayTeamUID(this._game)
    },

    _isSelected() {
      return selectionPaneStore().getSelectedGameUID() === this.gameUid;
    },
  },

  methods: {}
}
</script>

<template>
  <div class="line1">
    <team-logo :uid="_getGameHomeTeamUID"/>
    <div class="label">
      <team-name :uid="_getGameHomeTeamUID" class="team-home"/>

      <team-name :uid="_getGameAwayTeamUID" class="team-away"/>
    </div>
    <team-logo :uid="_getGameAwayTeamUID"/>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.line1 {
  display: flex;
  flex-direction: row;
  padding: @padding-small;
  border-radius: @radius-regular @radius-regular 0 0;

  .label {
    flex-grow: 1;
    text-align: center;

    .team-name {
      position: absolute;
      max-width: 40%;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 160px;
      white-space: nowrap;

      &.team-home {
        left: 0;
        text-align: left;
      }

      &.team-away {
        right: 0;
        text-align: right;
      }
    }

  }
}
</style>