<script>
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import PlayerPicture from "@/components/common/PlayerPicture.vue";
import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import {bookmakerStore} from "@/stores/Bookmaker.store";
import {offerStore} from "@/stores/Offer.store";

export default {
  name: "SelectionPaneItemOfferMatch",
  components: {OfferBookmakerValue, PlayerPicture, TeamLogo, LeagueLogo},
  props: {
    items: {
      type: Object,
      required: true
    },
    positive: {
      type: Boolean,
      default: true
    }
  },

  computed:{
    _buildCssClasses() {
      return {
        'offer-criteria flex': true,
        'positive': this.positive,
        'negative': !this.positive
      }
    },
    _isEmpty() {
      return !this.items || !this.items.length || this.items.length === 0
    }
  },

  methods: {
    _obvLabel(item, obv) {
      let os = offerStore();
      let base = os.getOfferFamilyChoiceLabelByUID(item.targetFixedUID, item.argument, item.condition.bookmakerUID)
      switch (item.condition.operator){
        case 'UPPER':
          base += 'sup. à '
          break
        case 'LOWER':
          base += 'inf. à '
          break
      }
      base += item.condition.value;

      if(item.condition.comparedToFixedUID){
        base += ' et '
        switch (item.condition.comparedToOperator){
          case 'UPPER':
            base += 'sup. à '
            break
          case 'LOWER':
            base += 'inf. à '
            break
        }
        base += os.getOfferFamilyChoiceLabelByUID(item.condition.comparedToFixedUID, item.condition.comparedToArgument, null)
      }

      return base
    },
    _obvValue(obv) {
      return obv.value
    },
    _bookmakerLogoSrc(bookmakerUID) {
      const bookmaker = bookmakerStore().get(bookmakerUID)
      if (!bookmaker) return null
      const lowerCaseBookName = bookmaker.name.toLowerCase().replaceAll(' ', '')
      return '/images/bookmakers/' + lowerCaseBookName + '.png'
    }
  }
}
</script>

<template>
  <div :class="_buildCssClasses" v-if="!_isEmpty">
    <div v-for="item in items" :key="item.id">
      <div v-for="obv in item.offerBookmakerValues" :key="obv.id" class="flex">
        <div class="value pl-2">
          {{ _obvValue(obv) }}
        </div>
        <div class="bookmaker">
          <img :src="_bookmakerLogoSrc(obv.bookmakerUID)" style="height: 24px; width: 24px;" alt=""/>
        </div>
        <div class="label ml-2 text-left py-1" style="line-height: normal">
          {{ _obvLabel(item, obv) }}
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped lang="less">
@import "@/assets/styles/base.less";

.offer-criteria {
  color: @text-color;
  font-size: @font-size-small;
  min-height: 24px;
  align-items: center;

  &:hover {
    background: @hover-color;
  }

  &.positive {
    background: -webkit-linear-gradient(0deg, @positive-color, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00);

    &:hover {
      background: -webkit-linear-gradient(0deg, @positive-color, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF00);
    }
  }

  &.negative {
    background: -webkit-linear-gradient(0deg, @negative-color, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00);

    &:hover {
      background: -webkit-linear-gradient(0deg, @negative-color, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF11, #FFFFFF00);
    }
  }

  .value {
    font-weight: bold;
    text-align: right;
    min-width: 40px;
    display: flex;
    align-items: center;
  }

  .bookmaker{
    display: flex;
    align-items: center;
    min-width: 24px;
  }
}
</style>