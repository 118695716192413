<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "IndicateurCircle",
  components: {FontAwesomeIcon},
  props: {
    type: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'medium'
    }
  },
  computed: {
    _buildCssClasses() {
      return {
        'circle': true,
        'positive': this.type === 'positive',
        'neutral': this.type === 'neutral',
        'negative': this.type === 'negative',
        'small': this.size === 'small',
        'medium': this.size === 'medium',
      }
    },
  },
}
</script>

<template>
  <div :class="_buildCssClasses">
    .
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.circle {
  width: @padding-large;
  height: @padding-large;
  min-width: @padding-large;
  min-height: @padding-large;
  line-height: @padding-large;
  border-radius: 50%;
  display: inline-block;
  color: transparent;

  &.small {
    width: @padding-regular;
    height: @padding-regular;
    min-width: @padding-regular;
    min-height: @padding-regular;
    line-height: @padding-regular;
  }

  &.neutral {
    background-color: @background-color-light;
  }

  &.negative {
    background-color: @negative-color;
  }

  &.positive {
    background-color: @positive-color;
  }
}
</style>