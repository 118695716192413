<script>

import {teamStore} from "@/stores/Team.store";

export default {
  name: "TeamName",
  props: {
    uid: {
      required: true
    },
    highlight: {
      type: Boolean,
      default: true
    },
    short: {
      type: Boolean,
      default: false
    },
    anonymous: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    _team() {
      if(this.anonymous) {
        return null;
      }
      if(!this.uid) {
        return null;
      }
      return teamStore().get(this.uid);
    },
    _teamName() {
      if(this.anonymous === true) {
        return "Anonyme";
      }
      if(!this._team) {
        return "_";
      }
      if (this.short) {
        const shortName = this._team?.shortName
        if (shortName) {
          return shortName
        }
      }
      return this._team?.name ?? "_";
    },
    _buildCssClasses() {
      let cssClasses = null
      if (!this._team && !this.anonymous) {
        cssClasses = {
          'team-name': true,
          'shine': true,
          'highlight': this.highlight
        }
      } else {
        cssClasses = {
          'team-name': true,
          'highlight': this.highlight
        }
      }

      return cssClasses;
    }
  },
}
</script>

<template>
  <span :class="_buildCssClasses">
    {{ _teamName }}
  </span>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.team-name {
  min-width: 16px;
  min-height: 16px;
  border-radius: @radius-small;
  line-height: 24px;
  padding: 0 @padding-small;
  color: @text-color;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.highlight {
    font-weight: bolder;
  }
}
</style>