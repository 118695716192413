<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import axiosService from "@/services/axios.service";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameDate from "@/components/common/GameDate.vue";
import LeagueName from "@/components/common/LeagueName.vue";

// Stores
import {selectionStore} from "@/stores/Selection.store";
import {sportStore} from "@/stores/Sport.store";
import {contextStore} from "@/stores/Context.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "SearchModal",
  components: {LeagueName, GameDate, TeamName, TeamLogo, LeagueLogo, FontAwesomeIcon},
  data() {
    return {
      filter: '',
      filterTimeout: null,

      data: {
        selections: [],
        leagues: [],
        teams: [],
        games: [],
      }

    }
  },

  computed: {
    _isVisible() {
      return modalStore().isVisibleModal('searchModal');
    },
    _selections() {
      const all = selectionStore().getAll
      if (this.filter === '') return []
      const matches = all.filter(selection => {
        return selection.name.toLowerCase().includes(this.filter.toLowerCase())
      })

      // sort by sport
      return matches.sort((a, b) => {
        if (a.sport < b.sport) return -1
        if (a.sport > b.sport) return 1
        return 0
      })
    },
    _hasResults() {
      if (!this.data) return false
      return this.data.selections.length > 0
          || this.data.leagues.length > 0
          || this.data.teams.length > 0
          || this.data.games.length > 0
    },

    _isMobile() {
      return contextStore().isMobileDevice()
    }
  },

  methods: {
    sportStore,
    _onCancel() {
      modalStore().closeModal();
    },
    _onKeyUp(e) {
      clearTimeout(this.filterTimeout)
      this.filterTimeout = setTimeout(() => {
        this._run()
      }, 120)
    },
    _run() {
      const payload = {
        filter: this.filter
      }
      this.data = null
      axiosService.put('/dta/search', payload).then(response => {
        this.data = response.data
        this.data.selections = this._selections
      })
    },
    _analyseGame(game) {
      modalStore().openModal({uid: 'gameModal', payload: game.uid})
    },
    _showTeamModal(team) {
      modalStore().openModal({uid: 'teamModal', payload: {uid: team.uid}})
    },
    _showLeagueModal(league) {
      modalStore().openModal({uid: 'leagueModal', payload: league.uid})
    },
  },

  watch: {
    _isVisible: function (visible) {
      if (visible) {
        setTimeout(() => {
          let searchInput = document.getElementById('search-input');
          if (searchInput && searchInput.focus) {
            searchInput.focus()
          }
        }, 100)
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header flex" style="padding-left: 0 !important">
      <a-input id="search-input" v-model:value="filter" placeholder="RECHERCHER" @keyup="_onKeyUp" class="transparent black"/>
    </header>

    <div class="content" v-if="_hasResults">
      <fieldset class="mt-2 sn" v-if="data.selections.length > 0">
        <legend>Sélections</legend>
        <div v-for="item in data.selections" class="search-result-item selection">
          <span>
            <font-awesome-icon :icon="sportStore().getIcons(item.sport)" class="mr-1"/>
          </span>
          {{ item.name }}
        </div>
      </fieldset>
      <fieldset class="mt-2 sn" v-if="data.leagues.length > 0">
        <legend>Compétitions</legend>
        <div v-for="item in data.leagues" class="search-result-item league" @click="_showLeagueModal(item)">
          <span>
            <font-awesome-icon :icon="sportStore().getIcons(item.sport)" class="mr-1"/>
          </span>
          <league-logo :uid="item.uid" class="mr-1" size="small"/>
          <league-name :uid="item.uid"/>
        </div>
      </fieldset>
      <fieldset class="mt-2 sn" v-if="data.teams.length > 0">
        <legend>Equipes</legend>
        <div v-for="item in data.teams" class="search-result-item team" @click="_showTeamModal(item)">
          <span>
            <font-awesome-icon :icon="sportStore().getIcons(item.sport)" class="mr-1"/>
          </span>
          <team-logo :uid="item.uid" class="mr-1" size="small"/>
          <team-name :uid="item.uid" class="mr-1"/>
        </div>
      </fieldset>
      <fieldset class="mt-2 sn" v-if="data.games.length > 0">
        <legend>Rencontres</legend>
        <div v-for="item in data.games" class="search-result-item game" @click="_analyseGame(item)">
          <span>
            <font-awesome-icon :icon="sportStore().getIcons(item.sport)" class="mr-1"/>
          </span>
          <team-logo :uid="item.homeTeamUID" class="mr-1" size="small"/>
          <team-name :uid="item.homeTeamUID" class="text-right" style="width: 200px"/>
          <div class="mx-1">-</div>
          <team-name :uid="item.awayTeamUID" class="mr-1" style="width: 200px"/>
          <team-logo :uid="item.awayTeamUID" class="mr-1 flex-grow" size="small"/>
          <span style="width: 60px;">
            <game-date :uid="item.uid"/>
            </span>
          <league-logo :uid="item.leagueUID" size="small"/>
        </div>
      </fieldset>
    </div>
    <div v-else class="flex  content-center items-center p-5">
      <font-awesome-icon icon="fa-solid fa-search" size="2xl" class="mr-2 primary"/>
      <p class="m-auto py-5">Tape le nom d'une équipe ou d'une compétition</p>
    </div>
    <template #footer>
      <div class="flex  m-2">
        <div class="flex-grow"/>
        <button class="info" @click="_onCancel">Annuler</button>
      </div>
    </template>
  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#search-input {
  border: none !important;
  height: @toolbar-regular;
  padding: @padding-regular !important;
  flex-grow: 1;
  font-size: @font-size-regular !important;

  &:focus {
    outline: none;
  }
}

.content {

  .search-result-item {
    display: flex;
    padding: @padding-regular;
    cursor: pointer;
    color: @text-color !important;
    line-height: 16px !important;
    align-items: center;

    svg {
      height: 16px !important;
    }

    &:hover {
      background-color: @hover-color;
    }
  }
}
</style>