<script>
import axiosService from "@/services/axios.service";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";

export default {
  name: "SelectionPaneItem.Score.Tab",
  components: {TeamLogo},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      data: null
    }
  },

  beforeMount() {
    const payload = {
      uid: this.gameUid,
    }
    axiosService.put('/dta/game/basic-data/score', payload).then(response => {
      this.data = response.data
    })
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid)
    },

    _homeTeamUID() {
      if (!this._game)
        return null

      return this._game.homeTeamUID
    },

    _awayTeamUID() {
      if (!this._game)
        return null

      return this._game.awayTeamUID
    },

    _dataValid() {
      return this.data && this.data.homeTeamExpectedGoals && this.data.homeTeamExpectedGoals
    },

    _goals() {
      if (!this.data) return []
      if (!this.data.homeProbaMap) return []
      return Object.keys(this.data.homeProbaMap)
    },

    // Retourne le top 3 des scores les plus probables
    _topScores() {
      if (!this.data) return []
      const results = []

      this._goals.forEach((h) => {
        this._goals.forEach((a) => {
          let proba = this._getProbaScore(h, a)
          if (proba > 0) {
            results.push({
              value: `${h}-${a}`,
              proba: proba
            })
          }
        })
      })
      return results.sort((a, b) => b.proba - a.proba).slice(0, 3)
    },

    _homeTeamExpectedGoals() {
      if (!this.data) return 0
      if (!this.data.homeTeamExpectedGoals) return 0
      return parseFloat(this.data.homeTeamExpectedGoals).toFixed(1)
    },

    _awayTeamExpectedGoals() {
      if (!this.data) return 0
      if (!this.data.awayTeamExpectedGoals) return 0
      return parseFloat(this.data.awayTeamExpectedGoals).toFixed(1)
    },
  },

  methods: {
    _getProba(side, nbGoal) {
      if (!this.data) return 0
      if (!this.data[side + 'ProbaMap']) return 0
      if (!this.data[side + 'ProbaMap'][nbGoal]) return 0
      let percent = this.data[side + 'ProbaMap'][nbGoal];
      if (!percent)
        return 0
      return parseFloat((percent * 100.0).toFixed(0))
    },
    _getProbaScore(home, away) {
      if (!this.data) return 0
      if (!this.data.homeProbaMap) return 0
      if (!this.data.homeProbaMap[home]) return 0
      if (!this.data.awayProbaMap) return 0
      if (!this.data.awayProbaMap[away]) return 0
      let percent = this.data.homeProbaMap[home] * this.data.awayProbaMap[away];
      if (!percent)
        return 0
      return parseFloat((percent * 100.0).toFixed(1))
    },
    getHighestProba(side) {
      if (!this.data) return 0
      if (!this.data.homeProbaMap) return 0
      let value = 0;
      Object.keys(this.data.homeProbaMap).forEach((key) => {
        let p = this._getProba(side, key);
        if (p > value) {
          value = p
        }
      })
      return value
    },
    getHighestProbaScore() {
      if (!this.data) return 0
      if (!this.data.homeProbaMap) return 0
      let value = 0;
      Object.keys(this.data.homeProbaMap).forEach((k1) => {
        Object.keys(this.data.awayProbaMap).forEach((k2) => {
          let p = this._getProbaScore(k1, k2);
          if (p > value) {
            value = p
          }
        })
      })
      return value
    },
    getProbaColorTransparency(proba, side) {
      let opacity = proba / this.getHighestProba(side);
      opacity = Math.pow(opacity, 2)
      return `rgba(0, 128, 0, ${opacity})`
    },
    getProbaScoreColorTransparency(proba) {
      let opacity = proba / this.getHighestProbaScore();
      opacity = Math.pow(opacity, 2)
      return `rgba(0, 128, 0, ${opacity})`
    }
  }
}
</script>

<template>
  <div>
    <div v-if="_dataValid">
      <fieldset class="m-2">
        <legend>Scores les + probables</legend>

        <div class="mb-2">
          <a-button-group v-for="score in _topScores" class="mx-1 inline-block">
            <button class="no-radius-right info">
              {{ score.value }}
            </button>
            <button class="no-radius-left info" :style="{'backgroundColor': getProbaScoreColorTransparency(score.proba)}">{{ score.proba }}%</button>
          </a-button-group>
        </div>
      </fieldset>
      <table>
        <tr>
          <th class="sr">
          </th>
          <th class="sr">
            xG
          </th>
          <th v-for="nbGoal in _goals">
            {{ nbGoal }}
          </th>
        </tr>
        <tr>
          <td class="sr">
            <team-logo :uid="_homeTeamUID"/>
          </td>
          <td class="sr">
            {{ _homeTeamExpectedGoals }}
          </td>
          <td v-for="nbGoal in _goals"
              :style="{'backgroundColor': getProbaColorTransparency(_getProba('home', nbGoal), 'home')}">
            {{ _getProba('home', nbGoal) }}%
          </td>
        </tr>
        <tr>
          <td class="sr">
            <team-logo :uid="_awayTeamUID"/>
          </td>
          <td class="sr">
            {{ _awayTeamExpectedGoals }}
          </td>

          <td v-for="nbGoal in _goals"
              :style="{'backgroundColor': getProbaColorTransparency(_getProba('away', nbGoal), 'away')}">
            {{ _getProba('away', nbGoal) }}%
          </td>
        </tr>
        <tr>
          <th :colspan="_goals.length + 2">
            Probabilité de chaque score
          </th>
        </tr>
        <tr>
          <td>
          </td>
          <td>
            <team-logo :uid="_awayTeamUID" size="small"/>
          </td>
          <td v-for="nbGoal in _goals">
            {{ nbGoal }}
          </td>
        </tr>
        <tr v-for="nbGoal in _goals">
          <td :rowspan="_goals.length" v-if="nbGoal < 1" class="sr">
            <team-logo :uid="_homeTeamUID" size="small"/>
          </td>

          <td>{{ nbGoal }}</td>
          <td v-for="nbGoal2 in _goals"
              :style="{'backgroundColor': getProbaScoreColorTransparency(_getProbaScore(nbGoal, nbGoal2))}">
            {{ _getProbaScore(nbGoal, nbGoal2) }}%
          </td>
        </tr>
      </table>
    </div>
    <a-alert v-else-if="!data" type="info" class="m-2" show-icon message="Chargement..."/>
    <a-alert v-else type="error" class="m-2" show-icon
             message="Les données disponibles ne permettent pas le calcul de probabibilité de chaque score."/>
    <hr/>
    <p class="m-2">Ces prédictions sont calculée en utilisant la loi de Poisson sur la base des résultats de la saison
      en cours.</p>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: @text-color;
  font-size: @font-size-small;

  tr {
    border-bottom: 1px solid @stroke-color;

    &:last-child {
      border-bottom: none;
    }

    th, td {
      padding: @padding-small;
      text-align: center;
      font-family: 'SourceCodePro', serif;
      font-weight: 400;
    }

    th {
      background-color: @background-color-light;
    }

    td {
      &.label {
        text-orientation: mixed;
        writing-mode: vertical-rl;

        &.ll {
          border-left: 1px solid @stroke-color;
        }
      }
    }
  }
}
</style>