<script>
import {modalStore} from "../../../../stores/Modal.store";
import {bankrollStore} from "../../../../stores/Bankroll.store";

export default {
  name: "CreateBankrollModal",
  data() {
    return {
      name: "",
      initialCapital: 100
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'createBankrollModal';
    }
  },
  methods: {
    _onConfirm() {
      this._onApply()
      this._onCancel()
    },

    _onApply() {
      if (!this.name) return
      bankrollStore().create(this.name, this.initialCapital)
    },

    _onCancel() {
      modalStore().closeModal()
    },
  },
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="400" @cancel="_onCancel">
    <header class="ant-modal-header">
      Nouvelle bankroll
    </header>

    <div class="p-1">
      <label class="w-2/5 inline-block">Nom</label>
      <input class="w-3/5" type="text" v-model="name" placeholder="Donne un nom à ta bankroll">
    </div>
    <div class="p-1">
      <label class="w-2/5 inline-block">Capital en départ</label>
      <input class="w-1/4" type="number" v-model="initialCapital" placeholder="Capital de départ">
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="mr-2 primary" @click="_onConfirm">Créer la bankroll</button>
        <button class="" @click="_onCancel">Annuler</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>