<script>
import LeagueButton from "@/components/common/LeagueButton.vue";
import {contextStore} from "@/stores/Context.store";
import {modalStore} from "@/stores/Modal.store";
import {statStore} from "@/stores/Statistic.store";

export default {
  name: "StatSelectorModal",
  components: {LeagueButton},

  data() {
    return {
      filter: '',
      statFixedUID: null,
    }
  },

  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },

    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'statSelectorModal';
    },

    _payload() {
      return modalStore().getVisibleModal.payload
    },

    _type(){
      if(!this._payload) return null
      return this._payload.type
    },

    _scope(){
      if(!this._payload) return null
      return this._payload.scope
    },

    _callback() {
      if(!this._payload) return null
      return this._payload.callback
    },

    _sport() {
      if(!this._payload) return 'FOOTBALL'
      return this._payload.sport
    },
    _statisticDefinitions() {
      let sdef = statStore().getDefinitions(this._sport)

      if(this._type) {
        sdef = sdef.filter(o => o.type === this._type)
      }
      if(this._scope) {
        sdef = sdef.filter(o => o.scope === this._scope)
      }

      // filter
      if(this.filter.length > 0) {
        return sdef.filter(o => o.name.toLowerCase().includes(this.filter.toLowerCase()))
      }
      return sdef
    },
    _chooseStatsMessage() {
      const count = this._statisticDefinitions.length
      return 'Choisissez une des ' + count + ' stats disponibles'
    },

    // On regroupe les stats par type_score
    _statisticDefinitionsOptionsGrouped(){
      let grouped = {}
      this._statisticDefinitions.forEach(sdef => {
        let key = sdef.type + '_' + sdef.scope
        if(!grouped[key]) grouped[key] = []
        grouped[key].push(sdef)
      })

      // On trie les groupes par clé
      let ordered = {}
      Object.keys(grouped).sort().forEach(function(key) {
        ordered[key] = grouped[key];
      });

      return ordered
    }
  },

  methods: {
    _onCancel() {
      modalStore().closeModal();
    },

    _onSelect(sdef) {
      if(this._callback)
        this._callback(sdef)
      modalStore().closeModal();
    },

    _groupLegend(key) {
      let parts = key.split('_')

      let result = ''
      switch (parts[0]){
        case 'FREQUENCY':
          result = "Fréquence d'un évènement"
          break
        case 'RAW':
          result = 'Donnée brute'
          break
        case 'SERIE':
          result = 'Série'
          break
      }

      switch (parts[1]){
        case 'PLAYER':
          result += ' par joueur'
          break
        case 'TEAM':
          result += ' par équipe'
          break
        case 'LEAGUE':
          result += ' par ligue'
          break
      }

      return result
    }
  },

  watch: {
    _isVisible() {
      if(this._isVisible) {
        this.statFixedUID = this._payload.statFixedUID
      }else{
        this.statFixedUID = null
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="480" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header pr-2">
      <a-input v-model:value="filter" placeholder="RECHERCHER UNE STATISTIQUE" class="transparent black"/>
    </header>

    <div class="content">
      <fieldset v-for="(group, key) in _statisticDefinitionsOptionsGrouped" class="mx-1 mb-2 mt-1">
        <legend>{{_groupLegend(key)}}</legend>
        <button @click="_onSelect(sdef)" class="text-left no-radius" v-for="sdef in group" style="width: 100%">
          {{sdef.name}}
        </button>
      </fieldset>
    </div>

    <template #footer>
      <div class="flex p-2">
        <a-alert class="flex-grow" :message="_chooseStatsMessage" type="info" show-icon/>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>