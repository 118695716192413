<script>
import PlayerName from "@/components/common/PlayerName.vue";
import PlayerPicture from "@/components/common/PlayerPicture.vue";
import {statStore} from "@/stores/Statistic.store";
import TeamLogo from "@/components/common/TeamLogo.vue";

export default {
  name: "ScorerRow",
  components: {TeamLogo, PlayerPicture, PlayerName},

  props: {
    stat: {
      required: true
    },
    side: {
      required: true
    }
  },

  computed: {
    _relativeValue() {
      if (!this.stat) return
      let ss = statStore();
      return ss.getValue(this.stat, 'CURRENT_SEASON')
    },
    _absoluteValue() {
      if (!this.stat) return
      let ss = statStore();
      return ss.getAbsoluteValue(this.stat, 'CURRENT_SEASON')
    },
    _relativeTdStyle() {
      if (!this.stat) return
      let ss = statStore();
      const value = ss.getValue(this.stat, 'CURRENT_SEASON')
      if (value === 0) return {}
      return {
        background: this._getProbaColorTransparency(value)
      }
    }
  },

  methods: {
    _getProbaColorTransparency(value) {
      const floatVal = parseFloat(value)
      let opacity = 1 / (1 / floatVal)
      return `rgba(0, 128, 0, ${opacity})`
    },
  }
}
</script>

<template>
  <tr>
    <td>
      <team-logo :uid="stat.whoUID"/>
    </td>
    <td class="p-0 m-0">
      <player-picture :uid="stat.playerUID"/>
    </td>
    <td class="text-left">
      <player-name :uid="stat.playerUID"/>
    </td>
    <td class="text-center">{{_absoluteValue}}</td>
    <td class="text-center" :style="_relativeTdStyle">
      {{_relativeValue}}
    </td>
  </tr>
</template>

<style scoped lang="less">

</style>