<script>
import axiosService from "@/services/axios.service";
import {contextStore} from "@/stores/Context.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import {perspectiveStore} from "@/stores/Perspective.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import {gameStore} from "@/stores/Game.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "ExpectedGoalsWidget",
  components: {
    WidgetLoadingContent,
    TeamLogo
  },
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      data: null
    }
  },
  mounted() {

    this._onGameUIDChange()

    if (!this.item) return;  // widget store preview
    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })

    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _homeXg() {
      if (!this.data) return null
      return this._formatXg(this.data.home)
    },
    _awayXg() {
      if (!this.data) return null
      return this._formatXg(this.data.away)
    },
    _gameUID() {
      return contextStore().gameUID
    },
    _game() {
      return gameStore().get(this._gameUID)
    },
    _gameHomeTeamUID() {
      return gameStore().getHomeTeamUID(this._game)
    },
    _gameAwayTeamUID() {
      return gameStore().getAwayTeamUID(this._game)
    },
    _widget() {
      if (!this._itemUID) return null
      return perspectiveStore().getWidgetByUID(this._itemUID)
    },
    _isWidgetYours() {
      if (!this._widget) return false
      return this._widget.userUID === contextStore().getLoggedUserUID()
    },
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },
    _formatXg(xg) {
      if (xg === null) return "-"
      if (xg <= 0) return "0.00"
      return xg.toFixed(2)
    },
    _refresh() {
      if (!contextStore().gameUID) return;

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) return;

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {

      if (!this.item) {  // widget store preview
        this.data = {
          home: 1.2,
          away: 3.4
        }
        return;
      }

      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        return;
      }

      const payload = {
        gameUID: this._gameUID,
        sport: perspectiveStore().getCurrentSport()
      }
      axiosService.put('/wid/expected-goals', payload).then(response => {
        this.data = response.data
        wds.put(this.item.uid, contextStore().gameUID, this.data)
      })
    },
    _deleteWidget() {
      if (!this._isWidgetYours) return
      perspectiveStore().deleteWidget(this._itemUID, () => {
        modalStore().closeModal();
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      xG - Fin de match
      <button class="secondary small no-radius" @click="_deleteWidget" v-if="_isWidgetYours"
              style="position: absolute; right:0; height: 27px">
        <font-awesome-icon icon="fa-solid fa-trash-can"/>
      </button>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content flex" v-else>

      <team-logo :uid="_gameHomeTeamUID" size="small"/>
      <a-tooltip title="Valeur calculée suivant la loi de poisson">
        <div class="flex-grow">{{ _homeXg }}</div>
      </a-tooltip>
      <div></div>
      <a-tooltip title="Valeur calculée suivant la loi de poisson">
        <div class="flex-grow">{{ _awayXg }}</div>
      </a-tooltip>
      <team-logo :uid="_gameAwayTeamUID" size="small"/>

    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.widget-content {
  font-size: 1.25em;
  line-height: 16px;
  padding: @padding-regular;
  color: @text-color;
  background: -webkit-linear-gradient(0deg, @home-color, @away-color);
}
</style>