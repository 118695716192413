<script>

import SelectionFavIndicator from "@/components/common/SelectionFavIndicator.vue";
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {colorStore} from "@/stores/Color.store";
import {offerStore} from "@/stores/Offer.store";
import {monitoringStore} from "@/stores/Monitoring.store";
import BankrollResume from "@/components/common/BankrollResume.vue";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import SelectionMonitoringItem from "@/components/common/SelectionMonitoringItem.vue";

export default {
  name: "SelectionItem",
  components: {SelectionMonitoringItem, FontAwesomeIcon, BankrollResume, SelectionFavIndicator},
  props: {
    value: {
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    showMonitoring: {
      type: Boolean,
      default: true
    },
    showControls: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    _selectionStyle() {
      let bgColor = colorStore().get(JSON.stringify(this.value));
      return {
        backgroundColor: bgColor,
        color: colorStore().getContrastColor(bgColor)
      }
    },

    _firstLetter() {
      if (!this.value) return '?';
      return this.value.name.charAt(0).toUpperCase()
    },

    _sportOfferFamilies() {
      let offerFamilyDefinitionsBySport = offerStore().getOfferFamilyDefinitionsBySport(contextStore().getSport);
      if (!offerFamilyDefinitionsBySport) return []
      return offerFamilyDefinitionsBySport.items
    },

    _leagueGroupLabel() {
      if (!this.value) return null
      const lg = leagueGroupsStore().get(this.value.leagueGroupUID)
      if (!lg) return null
      return lg.name
    },

    _offerTypeLabel() {
      let familyFixedUID = this.value.offerFamilyFixedUID
      if (!familyFixedUID) {
        return null
      }
      const family = this._sportOfferFamilies.find(f => f.fixedUID === parseInt(familyFixedUID))
      if (!family) {
        return null
      }
      let arg = this.value.offerArgument
      return offerStore().getOfferFamilyDefinitionLabel(family.code) + (arg ? ' ' + arg : '')
    },

    _offerChoiceLabel() {
      if (!this.value) return null
      const choiceFixedUID = this.value.offerFamilyChoiceFixedUID
      if (!choiceFixedUID) {
        return null
      }

      const ofc = offerStore().getOfferFamilyChoiceBySportAndFixedUID(this.value.sport, choiceFixedUID)
      return offerStore().getOfferFamilyChoiceLabel(ofc, this.value.offerArgument, false)
    },

    _editable() {

      if (!this.showControls) {
        return false
      }

      const cs = contextStore()
      if (!cs.loggedUser) {
        return false
      }
      return this.value.userUID === cs.loggedUser.uid
    },

    _allMonitoring() {
      if (!this.value) return []
      return monitoringStore().getBySelectionUID(this.value.uid)
    },

    _isYours() {
      const cs = contextStore()
      if (!cs.loggedUser) {
        return false
      }
      return this.value.userUID === cs.loggedUser.uid
    }
  },

  methods: {
    _openEditSelectionModal() {
      modalStore().openModal(
          {
            uid: "editSelectionModal",
            payload: {
              selection: this.value,
              sport: this.value.sport
            }
          }
      )
    },
  }

}
</script>

<template>
  <div :class="{'selection-item': true, 'selected': selected}">
    <div>
      <div class="selection-icon block" :style="_selectionStyle">
        {{ _firstLetter }}
      </div>
      <div class="fav-button-wrapper block pl-1 mt-2">
        <selection-fav-indicator :uid="value.uid" v-if="_isYours"/>
      </div>
    </div>
    <div class="selection-texts">
      <div class="flex">
        <label>{{ value.name }} </label>

        <div class="flex-grow"/>

        <button v-if="_editable" class="info small ml-1" @click.stop="_openEditSelectionModal()">
          Editer
        </button>
      </div>

      <div class="flex">
        <p>{{ value.description }}</p>

        <div class="flex-grow"/>


      </div>
      <div class="flex mt-1" v-if="_offerTypeLabel">
        <label class="small" v-if="_leagueGroupLabel">
          <font-awesome-icon icon="fa-solid fa-trophy"/>
          {{ _leagueGroupLabel }}
        </label>

        <div class="flex-grow"/>

        <label class="small">
          <font-awesome-icon icon="fa-solid fa-bullseye"/>
          {{ _offerTypeLabel }}
          <span v-if="_offerChoiceLabel"> / {{ _offerChoiceLabel }}</span>
        </label>
      </div>

      <div class="mt-1">
        <selection-monitoring-item :item="m" v-for="m in _allMonitoring" v-if="showMonitoring"/>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import "@/assets/styles/base.less";

.selection-item {
  padding: @padding-regular;
  cursor: pointer;
  text-align: left;
  display: flex;
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  border: 1px solid @stroke-color;
  border-radius: @radius-regular;

  flex: 1 1 320px; /* permet aux div de croître et se contracter, avec une base de 350px */
  box-sizing: border-box;
  max-width: calc(100%);

  &:hover {
    background: @title-color;
    background: linear-gradient(135deg, fade(@title-color, 20%) 0%, fade(@title-color, 15%) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  }

  .selection-icon {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px;
    max-height: 24px;
    border-radius: @radius-small;
    background-color: @primary-color;
    display: inline-block;
    margin-right: 8px;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
  }

  .selection-texts {

    flex-grow: 1;

    p {
      font-size: 11px !important;
      color: @text-color;
      margin: 0;
    }
  }

  &:hover {
    border: 1px solid @stroke-color;
  }

  &.selected {
    background: fade(@selected-color, 30%) !important;
  }
}
</style>