<script>

import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";
import IndicateurCircle from "@/components/common/IndicateurCircle.vue";

export default {
  name: "DrawNotBetCalculatorItem",
  components: {IndicateurCircle, OfferChoiceButton, OfferBookmakerValue},
  data() {
    return {
      form: {
        ticketOdd: 2.51,
        ticketStake: 2.50,
        cashoutPropose: 4.00,
        inverseOdd: 3.10,
        inverseStake: null
      }
    }
  },

  mounted() {
    this.form.inverseStake = (this._inverseOddProbability * this._gainPotentiel).toFixed(2)
  },

  computed: {
    _gainPotentiel() {
      return (this.form.ticketOdd * this.form.ticketStake).toFixed(2)
    },
    _inverseOddProbability() {
      if (!this.form.inverseOdd) return 0
      return 1 / this.form.inverseOdd
    },
    _inverseOddProbabilityFormatted() {
      return (this._inverseOddProbability * 100).toFixed(2) + '%'
    },
    _gainTicket1() {
      return this.form.ticketOdd * this.form.ticketStake - this.form.inverseStake
    },
    _gainTicket1Formatted() {
      return (this._gainTicket1).toFixed(2)
    },
    _gainTicket2() {
      return this.form.inverseOdd * this.form.inverseStake - this.form.inverseStake
    },
    _gainTicket2Formatted() {
      return (this._gainTicket2).toFixed(2)
    },
    _gainTicket1Prc() {
      const prc = 100 * this._gainTicket1 / this.form.cashoutPropose - 100.00;
      const formatted = prc.toFixed(2) + '%';
      if(prc > 0) {
        return '+' + formatted
      } else {
        return formatted
      }
    },
    _gainTicket2Prc() {
      const prc = 100 * this._gainTicket2 / this.form.cashoutPropose - 100.00;
      const formatted = prc.toFixed(2) + '%';
      if(prc > 0) {
        return '+' + formatted
      } else {
        return formatted
      }
    },
    _gainTicket1Color() {
      return this._gainTicket1 > this.form.cashoutPropose ? 'positive' : 'negative'
    },
    _gainTicket2Color() {
      return this._gainTicket2 > this.form.cashoutPropose ? 'positive' : 'negative'
    }
  },
  methods: {
    _onInverseOddChange() {
      this.form.inverseStake = (this._inverseOddProbability * this._gainPotentiel).toFixed(2)
    }
  }
}
</script>

<template>
  <div>
    <table>
      <tr>
        <th>
        </th>
        <td>
          T1
        </td>
        <td>
          T2
        </td>
      </tr>
      <tr>
        <th>
          Cote total
        </th>
        <td>
          <a-input type="number" v-model:value="form.ticketOdd" step='0.01' placeholder="Côte"
                   style="text-align: end; width: 50px"/>
        </td>
      </tr>
      <tr>
        <th>
          Mise
        </th>
        <td>
          <a-input type="number" v-model:value="form.ticketStake" step='0.01' placeholder="Côte"
                   style="text-align: end; width: 50px"/>
        </td>
      </tr>
      <tr>
        <th>
          Gain potentiel
        </th>
        <td>
          <a-input type="number" disabled :placeholder="_gainPotentiel" placeholder="Mise"
                   style="text-align: end; width: 50px"/>
        </td>
        <td></td>
      </tr>
      <tr>
        <th>
          Cashout proposé par le bookmaker
        </th>
        <td>
          <a-input type="number" v-model:value="form.cashoutPropose" step='0.01' placeholder="Côte"
                   style="text-align: end; width: 50px"/>
        </td>
        <td></td>
      </tr>
      <tr>
        <th>
          Côte événement inverse <span v-if="_inverseOddProbability > 0">({{ _inverseOddProbabilityFormatted }})</span>
        </th>
        <td></td>
        <td>
          <a-input type="number" v-model:value="form.inverseOdd" step='0.01' placeholder="Côte"
                   style="text-align: end; width: 50px" @change="_onInverseOddChange"/>
        </td>
      </tr>
      <tr>
        <th>
          Mise à placer sur l'événement inverse
        </th>
        <td></td>
        <td>
          <a-input type="number" v-model:value="form.inverseStake" step='0.01' placeholder="Côte"
                   style="text-align: end; width: 50px"/>
        </td>
      </tr>
      <tr>
        <th rowspan="2">Gains après optimisation</th>
        <td>
          <indicateur-circle :type="_gainTicket1Color" size="small"/>
          {{ _gainTicket1Formatted }}
        </td>
        <td>
          <indicateur-circle :type="_gainTicket2Color" size="small"/>
          {{ _gainTicket2Formatted }}
        </td>
      </tr>
      <tr>
        <td>
          {{ _gainTicket1Prc }}
        </td>
        <td>
          {{ _gainTicket2Prc }}
        </td>
      </tr>
    </table>
    <a-alert message="Pour battre le cashout, il faut placer une mise sur l'événement inverse." type="info" show-icon class="mt-2"/>
  </div>
</template>

<style scoped lang="less">

</style>