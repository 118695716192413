<script>


import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import axiosService from "@/services/axios.service";
import SelectionPaneItemLine1 from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.Line1.vue";
import SelectionPaneItemLineGameData
  from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.LineGameData.vue";
import {offerStore} from "@/stores/Offer.store";
import TicketBetOdd from "@/components/common/TicketBetOdd.vue";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";
import dateService from "@/services/date.service";
import {message} from "ant-design-vue";

export default {
  name: "AllMonitoringTicketsModal",
  components: {
    ArjelLogo,
    TicketBetOdd,
    SelectionPaneItemLineGameData,
    SelectionPaneItemLine1
  },
  data() {
    return {
      data: [],
      page: 1,
      pageSize: 20,

      tab: 'user', // user or best

      filters: {
        selectedDateInterval: 'TODAY',
        offerFamilyChoicesFixedUID: [],
        selectedLeagueUIDs: [],
        selectedLeagueLabel: 'Toutes',
        minOdd: 1.01,
        maxOdd: 10.0,
        targetOdd: 1
      },

      dateIntervalChoices: [
        {
          label: 'Aujourd\'hui',
          shortLabel: 'Auj.',
          code: 'TODAY'
        },
        {
          label: '24 prochaines heures',
          shortLabel: '24H',
          code: 'NEXT_24_HOURS'
        },
        {
          label: '3 prochains jours',
          shortLabel: '3J',
          code: 'NEXT_3_DAYS'
        },
        {
          label: '7 prochains jours',
          shortLabel: '7J',
          code: 'NEXT_7_DAYS'
        }
      ],
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false
      return visibleModal.uid === 'allMonitoringTicketsModal';
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _filteredItems() {
      if (!this.data) return []
      if (!this.data.results) return []

      // On va appliquer les filtres sur les items
      const dateInterval = dateService.buildDateInterval(this.filters.selectedDateInterval)
      const results = []
      this.data.results.forEach(r => {
        // Date interval
        if (dateService.isBefore(r.gameDate, dateInterval.start)) {
          return;
        }
        if (dateService.isAfter(r.gameDate, dateInterval.end)) {
          return;
        }

        // Competition
        if (this.filters.selectedLeagueUIDs.length > 0 && this.filters.selectedLeagueUIDs.indexOf(r.gameLeagueUID) === -1) {
          return;
        }

        const compatibleBets = []
        // Type de bets
        r.bets.forEach(bet => {
          let canKeep = true;
          if (this.filters.offerFamilyChoicesFixedUID.length > 0 && !this.filters.offerFamilyChoicesFixedUID.includes(bet.offerFamilyChoiceFixedUID)) {
            canKeep = false;
          }
          if (parseFloat(bet.odd) < parseFloat(this.filters.minOdd)) {
            canKeep = false;
          }
          if (parseFloat(bet.odd) > parseFloat(this.filters.maxOdd)) {
            canKeep = false;
          }
          if (canKeep) {
            compatibleBets.push(bet)
          }
        })

        if (compatibleBets.length === 0) {
          return;
        }

        const newR = {
          gameUID: r.gameUID,
          gameDate: r.gameDate,
          gameLeagueUID: r.gameLeagueUID,
          bets: compatibleBets
        }

        results.push(newR)

      })

      return results
    },
    _orderedItems() {
      return this._filteredItems.sort((a, b) => {
        return b.gameDate - a.gameDate
      })
    },
    _gameUIDs() {
      if (!this._orderedItems) return []
      let uids = this._orderedItems.map(i => parseInt(i.gameUID));
      if (!uids) return []
      return uids
    },
    _betUIDs() {
      if (!this._filteredItems) return []
      let uids = []
      this._filteredItems.forEach(item => {
        item.bets.forEach(bet => {
          uids.push(bet.uid)
        })
      })
      return uids
    },
    _shownedGamesUIDs() {
      return this._gameUIDs.slice(0, this.page * this.pageSize)
    },
    _selectedDateInterval() {
      return this.dateIntervalChoices.find(c => c.code === this.filters.selectedDateInterval);
    },
    _selectedDateIntervalLabel() {
      if (!this._selectedDateInterval) return ''
      return this._selectedDateInterval.shortLabel
    },
    _selectedLeaguesLabel() {
      let label = this.filters.selectedLeagueLabel;
      if (!label) return 'Toutes'
      return label
    },
    _arjelOnly() {
      return this.filters.arjelOnly
    },
    _leagueGroups() {
      let groups = leagueGroupsStore().getBySport(contextStore().getSport)
      groups.sort((a, b) => a.name.localeCompare(b.name))
      return groups
    },
    offerFamilyChoicesFixedUID() {
      // retourne la liste des choix d'offres proposés dans les tickets de monitoring
      if (!this.data) return []
      if (!this.data.results) return []
      let choices = []
      this.data.results.forEach(item => {
        item.bets.forEach(bet => {
          if (!choices.includes(bet.offerFamilyChoiceFixedUID)) {
            choices.push(bet.offerFamilyChoiceFixedUID)
          }
        })
      })
      return choices
    },
    _offerFamilyChoicesFixedUIDLabel() {
      if (!this.filters.offerFamilyChoicesFixedUID) return 'Tous'
      if (this.filters.offerFamilyChoicesFixedUID.length === 0) return 'Tous'
      if (this.filters.offerFamilyChoicesFixedUID.length === 1) {
        return offerStore().getOfferFamilyChoiceLabelByUID(this.filters.offerFamilyChoicesFixedUID[0], null, false)
      }
      return this.filters.offerFamilyChoicesFixedUID.length + ' types'
    },
    _offerFamilyChoicesOptions() {
      const options = []
      this.offerFamilyChoicesFixedUID.forEach(fixedUID => {
        console.log('fixedUID', fixedUID)
        options.push({
          label: offerStore().getOfferFamilyChoiceLabelByUID(fixedUID, null, false),
          fixedUID: fixedUID
        })
      })

      // sort by label
      options.sort((a, b) => a.label.localeCompare(b.label))

      return options
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal()
    },
    _loadNextPage() {
      this.page++
    },
    _gameBetsGrouped(gameUID) {
      let item = this._filteredItems.find(item => item.gameUID === gameUID)
      let allBets = item.bets;

      // group by offerFamilyChoiceFixedUID + offerArgument
      let groupedBets = {}
      allBets.forEach(bet => {
        const key = bet.offerFamilyChoiceFixedUID + '_' + bet.offerArgument
        if (!groupedBets[key]) {
          groupedBets[key] = []
        }
        groupedBets[key].push(bet)
      })

      return groupedBets
    },
    _betFamilyLabel(bet) {
      const offerFamily = offerStore().getOfferFamilyBySportAndFixedUID(bet.sport, bet.offerFamilyFixedUID)
      if (!offerFamily) {
        return null
      }
      return offerFamily.label
    },
    _betLabel(bet) {
      if (!bet) {
        return '...'
      }
      if (bet.label) {
        return bet.label
      }
      const offerFamilyChoice = offerStore().getOfferFamilyChoiceBySportAndFixedUID(bet.sport, bet.offerFamilyChoiceFixedUID)
      return offerStore().getOfferFamilyChoiceLabel(offerFamilyChoice, bet.offerArgument, false)
    },
    _onSelectDateInterval(option) {
      this.filters.selectedDateInterval = option
    },
    _switchArjelOnly() {
      this.filters.arjelOnly = !this.filters.arjelOnly
    },
    _clearLeaguesSelection() {
      this.filters.selectedLeagueLabel = 'Toutes'
      this.filters.selectedLeagueUIDs = []
    },
    _selectGroup(group) {
      this.filters.selectedLeagueLabel = group.name
      this.filters.selectedLeagueUIDs = group.values
    },
    _isOfferChoiceSelected(fixedUID) {
      return this.filters.offerFamilyChoicesFixedUID.includes(fixedUID)
    },
    _onSelectOfferFamilyChoiceUID(fixedUID) {
      if (this.filters.offerFamilyChoicesFixedUID.includes(fixedUID)) {
        this.filters.offerFamilyChoicesFixedUID = this.filters.offerFamilyChoicesFixedUID.filter(f => f !== fixedUID)
      } else {
        this.filters.offerFamilyChoicesFixedUID.push(fixedUID)
      }
    },
    _refreshData() {
      const selectedSport = contextStore().getSport
      if(!selectedSport) return

      const best = this.tab === 'best' ? 'true' : 'false'
      axiosService.get('/mon/tickets?best=' + best + '&sport=' + selectedSport).then(response => {
        this.page = 1
        this.data = response.data
        this.dataSport = selectedSport
      })
    },
    _openTicketGeneratorModal() {

      if(this._betUIDs.length === 0) {
        message.warn("Impossible de générer un ticket sans bet candidat.")
        return
      }

      modalStore().openModal({
        uid: 'ticketGeneratorModal',
        payload: {betCandidateUIDs: this._betUIDs}
      })
    }
  },

  mounted() {
    this._refreshData()
  },

  watch: {
    _isVisible(val) {
      if (val) {
        this.page = 1
        if(this.dataSport !== contextStore().getSport) {
          this._refreshData()
        }
      }
    },
    tab(val) {
      this._refreshData()
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="500" @cancel="_onCancel"
          :class="{'mobile': _isMobile}">
    <header class="ant-modal-header flex">
      Matchs repérés par tes monitoring
    </header>
    <div class="flex">
      <button class="no-radius tab" :class="{'selected': tab === 'user'}" @click="tab='user'">Tes sélections
      </button>
      <button class="no-radius tab" :class="{'selected': tab === 'best'}" @click="tab='best'">Top sélections
      </button>
    </div>
    <div class="m-1">
      <table>
        <tr style="font-size: 8pt;">
          <th class="p-0">Période</th>
          <th class="p-0">Ligues</th>
          <th class="p-0">Types</th>
          <th class="p-0" colspan="2">Côte/bet</th>
        </tr>
        <tr>
          <td>
            <a-dropdown :trigger="['click']" class="flex-grow-0">
              <button class="small info" @click.prevent>{{ _selectedDateIntervalLabel }}</button>
              <template #overlay>
                <a-menu>
                  <a-menu-item v-for="c in dateIntervalChoices" @click="_onSelectDateInterval(c.code)">{{
                      c.label
                    }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </td>
          <td>
            <a-dropdown :trigger="['click']" class="flex-grow-0">
              <a-tooltip placement="right" title="Sélectionner des compétitions">
                <button class="ml-1 info small">
                  {{ _selectedLeaguesLabel }}
                </button>
              </a-tooltip>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="_switchArjelOnly">
                    <div class="flex text-center">
                      <arjel-logo :height="24"/>
                      <button class="small primary ml-2" v-if="_arjelOnly===true">Uniquement</button>
                      <button class="small secondary ml-2" v-else-if="_arjelOnly===false">Hors ARJEL</button>
                      <button class="small info ml-2" v-else>Indifférent</button>
                    </div>
                  </a-menu-item>
                  <hr/>
                  <a-menu-item @click="_clearLeaguesSelection">
                    Toutes les compétitions
                  </a-menu-item>
                  <hr/>
                  <a-menu-item @click="_selectGroup(g)" v-for="g in _leagueGroups">
                    {{ g.name }}
                  </a-menu-item>
                  <hr/>
                </a-menu>
              </template>
            </a-dropdown>
          </td>
          <td>
            <a-dropdown :trigger="['click']" class="flex-grow-0">
              <button class="small info" @click.prevent>{{ _offerFamilyChoicesFixedUIDLabel }}</button>
              <template #overlay>
                <a-menu>
                  <a-menu-item v-for="c in _offerFamilyChoicesOptions"
                               :class="{'text-left':true, 'selected': _isOfferChoiceSelected(c.fixedUID)}"
                               @click="_onSelectOfferFamilyChoiceUID(c.fixedUID)">
                    {{ c.label }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </td>
          <td><input v-model="filters.minOdd" style="width: 40px" class="mr-1"></td>
          <td><input v-model="filters.maxOdd" style="width: 40px"></td>
        </tr>
      </table>
    </div>

    <div class="content" @scrollend="_loadNextPage">
      <a-alert v-if="_gameUIDs.length === 0" message="Aucune rencontre identifiée par tes monitorings pour le moment."
               type="info" show-icon class="m-1"/>
      <div class="game-bets mx-1 mb-2 mt-1" v-for="gameUID in _shownedGamesUIDs" :key="gameUID">
        <div class="flex">
          <div class="flex-grow">
            <selection-pane-item-line1 :game-uid="gameUID"/>
            <selection-pane-item-line-game-data :game-uid="gameUID"/>
            <hr/>
            <div class="flex mx-2 my-1" v-for="(group, key) in _gameBetsGrouped(gameUID)" :key="key">
              <label class="flex-grow" style="line-height: 24px">
                <font-awesome-icon icon="fa-solid fa-bullseye" size="xs" class="mr-1"/>
                <span v-if="_betFamilyLabel(group[0])">{{ _betFamilyLabel(group[0]) }} </span>
                {{ _betLabel(group[0]) }}
              </label>

              <a-badge :count="group.length" v-if="group.length > 1">
                <ticket-bet-odd :value="group[0].odd" type="info" class="ml-1"/>
              </a-badge>
              <ticket-bet-odd :value="group[0].odd" type="info" class="ml-1" v-else/>
            </div>
          </div>
        </div>
      </div>

    </div>

    <template #footer>
      <div class="flex p-1">
        <div class="flex-grow"/>
        <button class="primary m-1" @click="_openTicketGeneratorModal">Générer un ticket</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

@filters-height: 50px;
@source-height: 28px;
.content {
  background: @background-color-light;
}

.ant-modal.mobile .content {
  max-height: calc(100dvh - 40px - 40px - 8px - 8px - @filters-height - @source-height) !important;
}

.game-bets {
  background: rgb(255,255,255);
  background: linear-gradient(135deg, rgba(255,255,255,0.10) 0%, rgba(255,255,255,0.08) 20%, rgba(115,115,115,0.05) 33%, rgba(115,115,115,0.10) 100%);
  border-radius: @radius-regular;
  cursor: pointer;
  border: 1px solid #444;

  &:hover {
    background: @title-color;
    background: linear-gradient(135deg, fade(@title-color, 20%) 0%, fade(@title-color, 15%) 10%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  }

}
</style>