<script>
import {message} from "ant-design-vue";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {modalStore} from "@/stores/Modal.store";
import {preferencesStore} from "@/stores/Preferences.store";
import {contextStore} from "@/stores/Context.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";

export default {
  name: "TeamRouteHeader",
  components: {ArjelLogo},

  mounted() {
    window.emitter.on('stats-route:open-stat-selector-modal', () => {
      this._openStatSelectorModal()
    })
  },

  beforeUnmount() {
    window.emitter.off('stats-route:open-stat-selector-modal')
  },

  data() {
    return {
      dateIntervalChoices: [
        {
          label: 'Plus tôt aujourd\'hui',
          shortLabel: '+tôt',
          code: 'EARLIER_TODAY'
        },
        {
          label: 'Aujourd\'hui',
          shortLabel: 'Auj.',
          code: 'TODAY'
        },
        {
          label: '24 prochaines heures',
          shortLabel: '24H',
          code: 'NEXT_24_HOURS'
        },
        {
          label: '3 prochains jours',
          shortLabel: '3J',
          code: 'NEXT_3_DAYS'
        },
        {
          label: '7 prochains jours',
          shortLabel: '7J',
          code: 'NEXT_7_DAYS'
        }
      ],

      filter: '',
      filterTimeout: null
    }
  },

  computed:{
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _arjelOnly() {
      return selectionPaneStore().arjelOnly
    },
    _selectedDateInterval() {
      const trs = statsRouteStore()
      return this.dateIntervalChoices.find(c => c.code === trs.selectedDateInterval);
    },
    _selectedDateIntervalLabel() {
      if (!this._selectedDateInterval) return ''
      return this._selectedDateInterval.shortLabel
    },

    _leagueGroups() {
      let groups = leagueGroupsStore().getBySport(contextStore().getSport);
      groups.sort((a, b) => a.name.localeCompare(b.name))
      return groups
    },

    _selectedLeaguesLabel() {
      let label = statsRouteStore().selectedLeagueLabel;
      if (!label) return 'Toutes'
      return label
    }
  },

  methods:{
    _onSelectDateInterval(option) {
      if (option === 'NEXT_3_DAYS') {
        if (!contextStore().isAuthenticated()) {
          message.warn("Connectes toi pour avoir accès à 3 jours d'analyse")
          return
        }
      }
      if (option === 'NEXT_7_DAYS') {
        if (!contextStore().isAuthenticated()) {
          message.warn("Connectes toi pour avoir accès à 7 jours d'analyse")
          return
        }
      }
      statsRouteStore().setSelectedDateInterval(option);
    },
    _switchArjelOnly() {
      selectionPaneStore().switchArjelOnly()
      statsRouteStore().updateWidgets()
    },
    _useSportFavoritesLeagues() {
      statsRouteStore().setSelectedLeagueLabel('Favorites')
      let values = preferencesStore().getValues('LEAGUE', 'FAVORITE_LEAGUES_' + contextStore().getSport);
      if (!values || !values.length) {
        message.warn("Tu n'as pas définit de compétitions préférées pour ce sport")
        return
      }
      // convert string to number
      values = values.map(v => Number(v))
      statsRouteStore().setSelectedLeagueUIDs(values)

    },
    _clearLeaguesSelection() {
      statsRouteStore().setSelectedLeagueLabel('Toutes')
      statsRouteStore().setSelectedLeagueUIDs([])
    },
    _openLeagueGroupsModal() {
      modalStore().openModal({
        uid: "leagueGroupsModal",
        payload: {
          sport: contextStore().getSport
        }
      })
    },
    _onFilterChange() {
      clearTimeout(this.filterTimeout)
      this.filterTimeout = setTimeout(() => {
        statsRouteStore().setFilter(this.filter)
      }, 300)
    },
    _selectGroup(group) {
      statsRouteStore().setSelectedLeagueLabel(group.name)
      statsRouteStore().setSelectedLeagueUIDs(group.values)
    },
    _openStatSelectorModal() {
      modalStore().openModal({
        uid: "statSelectorModal",
        payload: {
          statFixedUID: null,
          sport: contextStore().getSport,
          callback: (sdef) => {

            if (!contextStore().isAuthenticated()) {
              message.warn("Tu dois être connecté pour ajouter une statistique")
              return
            }

            if(!statsRouteStore().getSelectedPerspectiveUID()){
              message.warn("Tu dois sélectionner une perspective pour ajouter une statistique")
              return
            }

            const widgets = perspectiveStore().getWidgetsByPerspectiveUID(statsRouteStore().getSelectedPerspectiveUID())
            switch (contextStore().getLicenseType()){
              case 'NONE':  // si pas de licence, limiter le nombre de widgets à 5
                if(widgets.length >= 5){
                  message.warn("Sans licence, tu ne peux pas ajouter plus de 5 widgets")
                  return
                }
                break
              case 'BASIC':  // si pas de licence ULTIMATE, limiter le nombre de widgets à 8
                if(widgets.length >= 8){
                  message.warn("Sans licence ULTIMATE, tu ne peux pas ajouter plus de 8 widgets.")
                  return
                }
                break
              case 'FULL': // si licence ULTIMATE, on limite a 16 widgets
                if(widgets.length >= 16){
                  message.warn("Il n'est pas possible d'ajouter plus de 16 widgets par perspective.")
                  return
                }
                break
            }

            let widgetType = null
            switch (sdef.type){
              case 'FREQUENCY':
                widgetType = 'FrequencyStatsWidget'
                break
              case 'RAW':
                widgetType = 'RawStatsWidget'
                break
              case 'SERIE':
                widgetType = 'SerieStatsWidget'
                break
            }

            let selectedPerspectiveUID = statsRouteStore().getSelectedPerspectiveUID();
            perspectiveStore().addWidget(selectedPerspectiveUID, widgetType, () => {
              // On récupère le widget ajouté
              const perspectiveWidgets = perspectiveStore().getWidgetsByPerspectiveUID(selectedPerspectiveUID)
              const widget = perspectiveWidgets[perspectiveWidgets.length - 1]

              if(!widget){
                message.error("Erreur lors de l'ajout du widget")
                return
              }

              // On modifie la configuration du widget pour spécifié la statistique utilisée.
              let parameterDefinition = {
                'key': 'stat_fixed_uid',
                'multiple': false,
              }
              perspectiveStore().setWidgetParameterValue(widget.uid, parameterDefinition, {'key' : sdef.fixedUID})
              perspectiveStore().saveWidgetParameters(widget.uid)
            })

          }
        }

      });

    }
  }
}
</script>

<template>
  <header class="p-1 flex">
    <button class="accent mr-1" @click="_openStatSelectorModal">
      <font-awesome-icon v-if="_isMobile" icon="fa-solid fa-plus"/>
      <span v-else>Ajouter une stat.</span>
    </button>
    <a-dropdown :trigger="['click']" class="flex-grow-0">
      <button class="info" @click.prevent>{{ _selectedDateIntervalLabel }}</button>
      <template #overlay>
        <a-menu>
          <a-menu-item v-for="c in dateIntervalChoices" @click="_onSelectDateInterval(c.code)">{{
              c.label
            }}
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>

    <a-dropdown :trigger="['click']" class="flex-grow-0">
      <a-tooltip placement="right" title="Sélectionner des compétitions">
        <button class="ml-1 info">
          <font-awesome-icon icon="fa-solid fa-trophy"/>
          {{ _selectedLeaguesLabel }}
        </button>
      </a-tooltip>
      <template #overlay>
        <a-menu>
          <a-menu-item @click="_switchArjelOnly">
            <div class="flex text-center">
              <arjel-logo :height="24"/>
              <button class="primary ml-2" v-if="_arjelOnly===true">Uniquement</button>
              <button class="secondary ml-2" v-else-if="_arjelOnly===false">Hors ARJEL</button>
              <button class="info ml-2" v-else>Indifférent</button>
            </div>
          </a-menu-item>
          <hr/>
          <a-menu-item @click="_useSportFavoritesLeagues">
            Compétitions préférées
          </a-menu-item>
          <a-menu-item @click="_clearLeaguesSelection">
            Toutes les compétitions
          </a-menu-item>
          <hr/>
          <a-menu-item @click="_selectGroup(g)" v-for="g in _leagueGroups">
            {{ g.name }}
          </a-menu-item>
          <hr/>
          <a-menu-item @click="_openLeagueGroupsModal">
            Gestion des groupes
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>

    <input v-model="filter" placeholder="Cherche une équipe..." class="ml-1 flex-grow" @keyup="_onFilterChange">
  </header>
</template>

<style scoped lang="less">

</style>