<script>
import TeamLogo from "@/components/common/TeamLogo.vue";
import TeamName from "@/components/common/TeamName.vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import LeagueName from "@/components/common/LeagueName.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import {leagueStore} from "@/stores/League.store";
import AreaFlag from "@/components/common/AreaFlag.vue";
import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";
import IndicateurCircle from "@/components/common/IndicateurCircle.vue";
import {statStore} from "@/stores/Statistic.store";
import {notebookStore} from "@/stores/NotebookStore";

export default {
  name: "SerieStatsWidgetRow",
  components: {
    IndicateurCircle,
    OfferChoiceButton,
    OfferBookmakerValue,
    AreaFlag,
    ArjelLogo,
    WidgetLoadingContent,
    LeagueLogo,
    FontAwesomeIcon,
    LeagueName,
    WidgetTeamParamButton,
    WidgetLocationParamButton,
    WidgetLeagueParamButton,
    WidgetPeriodParamButton,
    TeamName, TeamLogo
  },

  props: {
    row: {
      type: Object,
      required: true
    },
    even: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    _overContext() {
      return statsRouteStore().getOverContext
    },
    _rowClasses() {
      if (!this._overContext) return {
        'serie-stats-widget-row': true,
        'anonymous': !this.row.teamUID,
        'even': this.even
      }
      return {
        'serie-stats-widget-row': true,
        'anonymous': !this.row.teamUID,
        'even': this.even,
        'in-notebook' : notebookStore().isIn(this.row.gameUID),
        'over-context-team': this._overContext.teamUID && this._overContext.teamUID === this.row.teamUID,
        'over-context-game': this._overContext.gameUID && this._overContext.gameUID === this.row.gameUID,
        'over-context-league': this._overContext.leagueUID && this._overContext.leagueUID === this.row.leagueUID
      }
    },
    _leagueUID() {
      if (!this.row) return null
      return this.row.leagueUID
    },
    _leagueArjel() {
      const league = leagueStore().get(this._leagueUID)
      if (!league) return false
      return league.isArjel === true
    },
    _teamUID() {
      if (!this.row) return null
      return this.row.teamUID
    },
    _againstTeamUID() {
      if (!this.row) return null
      return this.row.againstTeamUID
    },
    _location(){
      if (!this.row) return null
      return this.row.location
    },
    _locationIcon() {
      if (!this._location) return null
      if (this._location === 'HOME') return 'fa-solid fa-house'
      if (this._location === 'AWAY') return 'fa-solid fa-plane'
      return 'fa-solid fa-question'
    },
    _percentage() {
      if (!this.row) return '-'
      if (!this.row.percentage) return 'RECORD'
      if(this.row.percentage === 1) return 'RECORD'
      return this.row.percentage
    },
    _formattedPercentage() {
      if (!this._percentage) return '-'
      if(this._percentage === 'RECORD') return 'RECORD'
      return (this._percentage * 100.0).toFixed(1) + '%'
    },
    _absolute() {
      if (!this.row) return '-'
      if (!this.row.absolute) return '-'
      return this.row.absolute
    },
    _statFixedUID(){
      if(!this.row) return null
      return this.row.statFixedUID
    },
    _offerChoiceFixedUIDs() {
      if (!this._statFixedUID) return null
      return statStore().getOfferChoiceFixedUIDBySdefFixedUID(this._statFixedUID);
    },
    _offerChoiceFixedUID() {
      if (!this._offerChoiceFixedUIDs) return null
      if(!this._location) return null
      return this._offerChoiceFixedUIDs[this._location]
    },
    _obv() {
      if (!this.row) return null
      if (!this.row.offerBookmakerValues) return null
      if(!this._offerChoiceFixedUID) return null
      return this.row.offerBookmakerValues.find(obv => obv.offerFamilyChoiceFixedUID === this._offerChoiceFixedUID)
    },
    _odd() {
      if (!this._obv) return null
      return this._obv.value
    },
    _formattedOdd() {
      if (!this._odd) return '-.--'
      return this._odd.toFixed(2)
    },
    _oddProbability() {
      if (!this._obv) return null
      return 1.0 / this._obv.value
    },
    _probabilityDifference(){
      if (!this._oddProbability) return null
      if(this._formattedPercentage === 'RECORD') return 1.0
      if (!this._percentage) return null
      return this._percentage - this._oddProbability
    },
    _formattedProbabilityDifference() {
      if(this._formattedPercentage === 'RECORD') return 'Value'
      if (!this._probabilityDifference) return '-'
      return (this._probabilityDifference * 100.0).toFixed(1) + '%'
    },
    _isOddValue() {
      if(this._formattedPercentage === 'RECORD') return true
      if (!this._probabilityDifference) return null
      return this._probabilityDifference > 0
    }

  }
}
</script>

<template>
  <div :class="_rowClasses">
    <table>
      <tr>
        <td rowspan="2" style="text-align: center; width: 40px">
          <team-logo :uid="_teamUID"/>
        </td>
        <td>
          <team-name :uid="_teamUID"/>
        </td>
        <td class="relative-value">
          <span class="absolute-value">({{ _absolute }})</span> {{ _formattedPercentage }}
        </td>
      </tr>
      <tr>
        <td class="context-data">
          <font-awesome-icon :icon="_locationIcon" class="mr-1"/>
          <team-name :uid="_againstTeamUID" :anonymous="!_againstTeamUID"/>
          <arjel-logo :height="10" v-if="_leagueArjel" style="display: inline-block"/>
        </td>
        <td class="odd-value">
          <span v-if="_probabilityDifference && _isOddValue" class="value-percentage green">+{{_formattedProbabilityDifference}}</span>
          <span v-if="_probabilityDifference && !_isOddValue" class="value-percentage red">{{_formattedProbabilityDifference}}</span>
          {{ _formattedOdd }}
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.serie-stats-widget-row {
  cursor: pointer;
  display: flex;
  border-top: 1px solid @stroke-color;
  border-left: 8px solid transparent;
  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid @stroke-color;
  }

  &.even {
    background-color: @background-color-dark;
  }

  tr, td {
    background: transparent !important;
    text-align: left;
    border: none !important;
    padding: 0;
  }

  &.in-notebook{
    border-left: 8px solid @selected-color;
  }

  &.over-context-league {
    background: fade(@hover-color, 50%);
  }

  &.over-context-game {
    background: fade(@selected-color, 40%);
  }

  &.over-context-team {
    background: fade(@primary-color, 15%);
  }

  &:hover {
    background: fade(@primary-color, 15%);
  }

  &.anonymous {
    opacity: 0.25;
  }

  .context-data {
    font-size: 8pt;
    color: @text-color-dark;
  }

  .relative-value {
    width: 100px;
    text-align: right;
    padding: 0 @padding-small;
  }
  .absolute-value {
    color: @text-color-dark;
  }

  .odd-value {
    width: 100px;
    text-align: right;
    padding: 0 @padding-small;
  }

  .value-percentage{
    font-size: 10pt;
    margin-right: @padding-regular;

    &.green{
      color: @positive-color;
    }

    &.red{
      color: @negative-color;
    }

  }
}
</style>