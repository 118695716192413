<script>
import SelectionPane from "@/components/routes/selection/panes/selection/SelectionPane.vue";
import AnalysePane from "@/components/routes/selection/panes/analyse/AnalysePane.vue";
import MainHeader from "@/components/common/MainHeader.vue";
import EditSelectionModal from "@/components/routes/selection/modals/EditSelectionModal.vue";
import EditSelectionConditionModal from "@/components/routes/selection/modals/EditSelectionConditionModal.vue";
import CreateSelectionModal from "@/components/routes/selection/modals/CreateSelectionModal.vue";
import UserPreferencesModal from "@/components/common/modal/user_preferences/UserPreferencesModal.vue";
import StatisticModal from "@/components/common/modal/statistic/StatisticModal.vue";
import GameModal from "@/components/common/modal/game/GameModal.vue";
import WelcomePane from "@/components/routes/selection/panes/welcome/WelcomePane.vue";
import {contextStore} from "@/stores/Context.store";
import SelectionsModal from "@/components/routes/selection/modals/SelectionsModal.vue";
import {message} from 'ant-design-vue';
import SelectionLeagueModal from "@/components/common/modal/selection_league_modal/SelectionLeagueModal.vue";
import OfferModal from "@/components/common/modal/offer/OfferModal.vue";
import WidgetSettingsModal from "@/components/common/modal/widget_settings/WidgetSettingsModal.vue";
import WidgetStoreModal from "@/components/routes/selection/modals/widget_store/WidgetStoreModal.vue";
import SearchModal from "@/components/common/modal/search/SearchModal.vue";
import TeamModal from "@/components/common/modal/team/TeamModal.vue";
import LeagueModal from "@/components/common/modal/league/LeagueModal.vue";
import PerspectiveSettingsModal from "@/components/common/modal/perspective_settings/PerspectiveSettingsModal.vue";
import PaypalModal from "@/components/common/modal/paypal/PaypalModal.vue";
import SelectionMonitoringListModal
  from "@/components/routes/selection/modals/selection_monitoring_list/SelectionMonitoringListModal.vue";
import LicenceLeagueModal from "@/components/common/modal/LicenceLeagueModal.vue";
import CreateMonitoringModal from "@/components/routes/selection/modals/CreateMonitoringModal.vue";
import LeagueGroupModal from "@/components/common/modal/selection_league_modal/LeagueGroupModal.vue";
import EditBankrollModal from "@/components/routes/bankroll/modals/EditBankrollModal.vue";
import CalculatorModal from "@/components/common/modal/calculator/CalculatorModal.vue";
import LeagueGroupsModal from "@/components/common/modal/selection_league_modal/LeagueGroupsModal.vue";
import AllMonitoringTicketsModal from "@/components/routes/selection/modals/AllMonitoringTicketsModal.vue";

export default {
  name: "SelectionRoute",
  components: {
    AllMonitoringTicketsModal,
    LeagueGroupsModal,
    CalculatorModal,
    EditBankrollModal,
    LeagueGroupModal,
    CreateMonitoringModal,
    LicenceLeagueModal,
    SelectionMonitoringListModal,
    PaypalModal,
    PerspectiveSettingsModal,
    LeagueModal,
    TeamModal,
    SearchModal,
    WidgetStoreModal,
    WidgetSettingsModal,
    OfferModal,
    SelectionLeagueModal,
    SelectionsModal,
    GameModal,
    StatisticModal,
    UserPreferencesModal,
    CreateSelectionModal,
    EditSelectionConditionModal,
    EditSelectionModal, MainHeader, AnalysePane, SelectionPane,
    WelcomePane
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _selectedGameUID() {
      return contextStore().getSelectedGameUID()
    }
  },
  beforeMount() {
    if (contextStore().shouldReload()) {
      window.location.href = window.location.origin
    }

    if (!contextStore().isInitialized()) {
      this.$router.push({name: 'init'})
    }
  },
  mounted() {
    if (!contextStore().isInitialized()) return;
    if (!contextStore().isAuthenticated()) return;
    message.info("Salut " + contextStore().loggedUser.name + " !")

    // On met a jour le nom de la route courante
    contextStore().setRoute('main')
  }
}
</script>

<template>
  <div :class="{'mobile': _isMobile}">
    <main-header/>
    <div class="flex">
      <selection-pane/>
      <analyse-pane v-if="!_isMobile && _selectedGameUID"/>
      <welcome-pane v-else-if="!_isMobile"/>
    </div>

  </div>
</template>

<style scoped lang="less">

</style>