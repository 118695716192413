<template>
  <div class="selection-condition-item" @click="_openEditSelectionConditionModal">
    <div class="selection-condition-item-content">
      {{ _label }}
    </div>
  </div>
</template>
<script>

import {statStore} from "@/stores/Statistic.store";
import {offerStore} from "@/stores/Offer.store";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "SelectionConditionItem",
  components: {},

  props: {
    item: {
      type: Object,
      required: true,
    },
    sport: {
      type: String,
      required: true,
    }
  },

  data() {
    return {}
  },

  methods: {
    _openEditSelectionConditionModal() {
      modalStore().editSelectionConditionModalOpen = this.item;
    }
  },

  computed: {
    _targetType() {
      if (!this.item) return null;
      return this.item.targetType;
    },

    _target() {
      if (!this.item) return null
      switch (this._targetType) {
        case 'OFFER_FAMILY_CHOICE':
          return offerStore().getOfferFamilyChoiceBySportAndFixedUID(this.sport, this.item.targetFixedUID)
        case 'STATISTIC_DEFINITION':
          return statStore().getDefinitionByFixedUID(this.item.targetFixedUID)
      }
    },

    _label() {
      if (!this._target) return '?'
      switch (this._targetType) {
        case 'OFFER_FAMILY_CHOICE':
          return offerStore().getOperandLabel(this._target, this.item)
        case 'STATISTIC_DEFINITION':
          return statStore().getOperandLabel(this._target, this.item)
      }
    },

  }
}
</script>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.selection-condition-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .selection-condition-item-content {
    flex-grow: 1;
    cursor: pointer;
    padding: 0 @padding-regular;

    &:hover {
      background-color: @hover-color;
    }
  }
}
</style>