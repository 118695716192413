<template>
  <div id="selection-pane-wrapper">
    <div id="selection-pane">

      <selection-pane-header/>

      <header class="px-1 py-1 flex">
        <a-dropdown :trigger="['click']" class="flex-grow-0">
          <button class="info" @click.prevent>{{ _selectedDateIntervalLabel }}</button>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="c in dateIntervalChoices" @click="_onSelectDateInterval(c.code)">{{
                  c.label
                }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

        <a-dropdown :trigger="['click']" class="flex-grow-0">
          <a-tooltip placement="right" title="Sélectionner des compétitions">
            <button class="ml-1 info">
              <font-awesome-icon icon="fa-solid fa-trophy"/>
              {{ _selectedLeaguesLabel }}
            </button>
          </a-tooltip>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="_switchArjelOnly">
                <div class="flex text-center">
                  <arjel-logo :height="24"/>
                  <button class="primary ml-2" v-if="_arjelOnly===true">Uniquement</button>
                  <button class="secondary ml-2" v-else-if="_arjelOnly===false">Hors ARJEL</button>
                  <button class="info ml-2" v-else>Indifférent</button>
                </div>
              </a-menu-item>
              <hr/>
              <a-menu-item @click="_useSportFavoritesLeagues">
                Compétitions préférées
              </a-menu-item>
              <a-menu-item @click="_clearLeaguesSelection">
                Toutes les compétitions
              </a-menu-item>
              <hr/>
              <a-menu-item @click="_selectGroup(g)" v-for="g in _leagueGroups">
                {{ g.name }}
              </a-menu-item>
              <hr/>
              <a-menu-item @click="_openLeagueGroupsModal">
                Gestion des groupes
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

        <input v-model="filter" placeholder="Cherche une équipe..." class="ml-1 flex-grow">

      </header>
      <div class="selection-results">
        <div class="selection-results-content">

          <div class="flex mx-1 my-2">

            <a-dropdown :trigger="['click']" class="flex-grow-0">
              <button class="small info mr-1">
                <font-awesome-icon icon="fa-solid fa-bullseye"/>
                {{ _offerTypeLabel }}
              </button>
              <template #overlay>
                <a-menu>
              <span v-for="c in _sportOfferFamilyChoices">
                <a-menu-item @click="_onFamilyChange(c.value, null)" v-if="c.args === null">
                  {{ c.label }}
                </a-menu-item>
                <a-sub-menu :key="c.value" :title="c.label" v-else>
                  <a-menu-item v-for="arg in c.args" @click="_onFamilyChange(c.value, arg)">
                    {{ arg }}
                  </a-menu-item>
                </a-sub-menu>
              </span>
                </a-menu>
              </template>
            </a-dropdown>

            <a-dropdown :trigger="['click']" class="flex-grow-0">
              <button class="small info mr-1">
                <font-awesome-icon icon="fa-solid fa-object-group"/>
                {{ _critereRegroupementLabel }}
              </button>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="_setCritereRegroupement('LEAGUE')">
                    Grouper par compétition
                  </a-menu-item>
                  <a-menu-item @click="_setCritereRegroupement('DATETIME')">
                    Grouper par date/heure
                  </a-menu-item>
                  <a-menu-item @click="_setCritereRegroupement('NONE')">
                    Ne pas grouper
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>

            <a-dropdown :trigger="['click']" class="flex-grow-0">
              <button class="small info">
                <font-awesome-icon icon="fa-solid fa-sort-amount-desc"/>
                {{ _critereTriLabel }}
              </button>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="_setCritereTri('NOTE')">
                    Trier par note de correspondance
                  </a-menu-item>
                  <a-menu-item @click="_setCritereTri('DATETIME')">
                    Trier par date/heure
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <div class="flex-grow"/>
          </div>

          <a-alert v-if="!_isRunningSelection && _gameAnalysedCountMessage" :message="_gameAnalysedCountMessage"
                   class="mx-1" type="info"
                   style="padding: 2px 8px"/>
          <a-alert v-if="_resultsCount === 0 && _filterActive" :message="_filterActiveMessage" class="mx-1 my-1"
                   type="info"
                   style="padding: 2px 8px"/>
          <a-alert v-if="_arjelOnly === false" type="warning" class="mx-1 my-1" message="Filtre ARJEL en place"
                   show-icon/>
          <button v-if="!_isRunningSelection && _resultsCount === 0 && _nextDateInterval"
                  class="small tertiary m-2 block" @click="_onSelectDateInterval(_nextDateInterval.code)">
            Elargir la période à {{ _nextDateInterval.label }}
          </button>
          <div class="not-allowed-results p-1" v-if="_notAllowedLowLevel">
            <a-alert :message="_notAllowedResultsMessage"
                     type="warning" show-icon/>
            <fieldset class="not-allowed-leagues mt-1" v-if="_notAllowedLeaguesPRO && _notAllowedLeaguesPRO.length > 0">
              <legend class="m-0">{{ _notAllowedResultsCountPRO }} dans l'offre PRO</legend>
              <div class="not-allowed-league" v-for="l in _notAllowedLeaguesPRO">
                <league-logo :uid="l" size="small" style="margin-top: 1px"/>
                <league-name :uid="l" class="ml-1 inline-block" size="small" :show-area="true"/>
              </div>
            </fieldset>
            <fieldset class="not-allowed-leagues mt-1"
                      v-if="_notAllowedLeaguesULTIMATE && _notAllowedLeaguesULTIMATE.length > 0">
              <legend class="m-0">{{ _notAllowedResultsCountULTIMATE }} dans l'offre ULTIMATE</legend>
              <div class="not-allowed-league" v-for="l in _notAllowedLeaguesULTIMATE">
                <league-logo :uid="l" size="small" style="margin-top: 1px"/>
                <league-name :uid="l" class="ml-1 inline-block" size="small" :show-area="true"/>
              </div>
            </fieldset>
            <div class="flex mt-1">
              <button class=" accent flex-grow " @click="_buy('BASIC')"
                      v-if="_notAllowedLeaguesPRO && _notAllowedLeaguesPRO.length > 0">
                Passer PRO
              </button>
              <div v-if="_notAllowedLeaguesPRO && _notAllowedLeaguesULTIMATE">&nbsp;</div>
              <button class=" primary flex-grow " @click="_buy('FULL')"
                      v-if="_notAllowedLeaguesULTIMATE && _notAllowedLeaguesULTIMATE.length > 0">
                Passer ULTIMATE
              </button>
            </div>
          </div>

          <font-awesome-icon icon="fa-solid fa-spinner" :spin="true" size="2xl" style="color: #6DFFC2"
                             v-if="_isRunningSelection" class="m-3"/>
          <div v-else class="selection-pane-groups" v-for="(key, index) in _orderedGroupementKeys">
            <h1 class="m-2 flex" v-if="_groupedResults[key]">
          <span class="ml-2 mr-2 mt-0" v-if="_groupedResults[key].leagueUID">
            <league-fav-indicator :uid="_groupedResults[key].leagueUID"/>
          </span>
              <span class="flex-grow text-left">
            {{ _groupedResults[key].label }}
          </span>
              <span class="mr-2" v-if="_groupedResults[key].leagueUID">
            <league-logo :uid="_groupedResults[key].leagueUID"/>
          </span>
              <span class="mr-2" v-if="_groupedResults[key].area">
            <area-flag :area="_groupedResults[key].area"/>
          </span>

            </h1>
            <selection-pane-item v-for="(item, itemIndex) in _groupedResults[key].items" :key="item.gameUID"
                                 v-if="_groupedResults[key]"
                                 :item="item" :show-ad="index===2 && itemIndex===0" class="mb-3 mx-1"/>
          </div>
          <button class="primary mb-5" v-if="_canLoadMore" @click="_loadNextPage">
            Afficher plus de résultats
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SelectionCondition from "@/components/routes/selection/panes/selection/condition/SelectionOperation.vue";
import SelectionOperation from "@/components/routes/selection/panes/selection/condition/SelectionOperation.vue";
import SelectionPaneHeader from "@/components/routes/selection/panes/selection/header/SelectionPaneHeader.vue";
import Offer from "@/components/routes/selection/panes/selection/offers/Offer.vue";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";

import Statistic from "@/components/common/Statistic.vue";
import TeamName from "@/components/common/TeamName.vue";
import LeagueName from "@/components/common/LeagueName.vue";
import SelectionPaneItem from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.vue";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";
import {preferencesStore} from "@/stores/Preferences.store";
import {leagueStore} from "@/stores/League.store";
import {offerStore} from "@/stores/Offer.store";
import {selectionStore} from "@/stores/Selection.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import AreaFlag from "@/components/common/AreaFlag.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import LeagueFavIndicator from "@/components/common/LeagueFavIndicator.vue";
import dateService from "@/services/date.service";
import {modalStore} from "@/stores/Modal.store";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";
import {gameStore} from "@/stores/Game.store";
import LeagueButton from "@/components/common/LeagueButton.vue";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import {notebookStore} from "@/stores/NotebookStore";

export default {
  name: "SelectionPane",
  components: {
    ArjelLogo,
    LeagueButton,
    LeagueFavIndicator,
    LeagueLogo,
    AreaFlag,
    FontAwesomeIcon,
    SelectionPaneItem,
    TeamName, LeagueName,
    SelectionPaneHeader,
    Offer, SelectionOperation, SelectionCondition, Statistic
  },

  data() {
    return {
      dateIntervalChoices: [
        {
          label: 'Plus tôt aujourd\'hui',
          shortLabel: '+tôt',
          code: 'EARLIER_TODAY'
        },
        {
          label: 'Aujourd\'hui',
          shortLabel: 'Auj.',
          code: 'TODAY'
        },
        {
          label: '24 prochaines heures',
          shortLabel: '24H',
          code: 'NEXT_24_HOURS'
        },
        {
          label: '3 prochains jours',
          shortLabel: '3J',
          code: 'NEXT_3_DAYS'
        },
        {
          label: '7 prochains jours',
          shortLabel: '7J',
          code: 'NEXT_7_DAYS'
        }
      ],

      displayedPage: 1,  // pagination en front, tous les resultats sont chargés en une fois

      filter: '',
      filterTimeout: null
    }
  },

  mounted() {
    selectionPaneStore().init()
    selectionPaneStore().runSelection();
  },

  methods: {

    _loadNextPage() {
      if (!this._canLoadMore) return
      this.displayedPage += 1
    },
    _game(uid) {
      if (!uid) return null
      return gameStore().get(uid)
    },
    _onSelectDateInterval(option) {
      if (option === 'NEXT_3_DAYS') {
        if (!this._contextStore.isAuthenticated()) {
          message.warn("Connectes toi pour avoir accès à 3 jours d'analyse")
          return
        }
      }
      if (option === 'NEXT_7_DAYS') {
        if (!this._contextStore.isAuthenticated()) {
          message.warn("Connectes toi pour avoir accès à 7 jours d'analyse")
          return
        }
      }
      selectionPaneStore().selectedDateInterval = option;

      // On relance la recherche
      this.displayedPage = 1
      selectionPaneStore().runSelection();
    },
    _switchArjelOnly() {
      selectionPaneStore().switchArjelOnly()

      // On relance la recherche
      this.displayedPage = 1
      selectionPaneStore().runSelection();
    },
    _useSportFavoritesLeagues() {
      selectionPaneStore().setSelectedLeagueLabel('Favorites')
      let values = this._preferencesStore.getValues('LEAGUE', 'FAVORITE_LEAGUES_' + this._contextStore.getSport);
      if (!values || !values.length) {
        message.warn("Tu n'as pas définit de compétitions préférées pour ce sport")
        return
      }
      // convert string to number
      values = values.map(v => Number(v))
      selectionPaneStore().selectedLeagueUIDs = values;

      // On relance la recherche
      this.displayedPage = 1
      selectionPaneStore().runSelection();
    },
    _clearLeaguesSelection() {
      selectionPaneStore().setSelectedLeagueLabel('Toutes')
      selectionPaneStore().setSelectedLeagueUIDs([])

      // On relance la recherche
      this.displayedPage = 1
      selectionPaneStore().runSelection();
    },
    _selectGroup(group) {
      selectionPaneStore().setSelectedLeagueLabel(group.name)
      selectionPaneStore().setSelectedLeagueUIDs(group.values)

      // On relance la recherche
      this.displayedPage = 1
      selectionPaneStore().runSelection();
    },
    _openLeagueGroupsModal() {
      modalStore().openModal({
        uid: "leagueGroupsModal",
        payload: {
          sport: this._contextStore.getSport
        }
      })
    },
    _setCritereRegroupement(option) {
      const cs = contextStore()
      if (!cs.isAuthenticated()) {
        message.warning("Il faut être connecté pour changer le critère de regroupement.");
        return;
      }

      preferencesStore().save('SELECTION', 'CRITERE_REGROUPEMENT', [option])
      this.displayedPage = 1
    },
    _setCritereTri(option) {
      const cs = contextStore()
      if (!cs.isAuthenticated()) {
        message.warning("Il faut être connecté pour changer le tri.");
        return;
      }

      preferencesStore().save('SELECTION', 'CRITERE_TRI', [option])
      this.displayedPage = 1
    },

    _onFamilyChange(uid, arg) {

      selectionPaneStore().setOfferFamilyFixedUID(uid)
      selectionPaneStore().setOfferArgument(arg)

      let ss = selectionStore();
      const selection = ss.get(selectionPaneStore().selectionActiveUID)
      if (selection && selection.offerFamilyFixedUID == null) {
        this._preferencesStore.save('BETTING', 'FAVORITE_OFFER_FAMILY_' + this._contextStore.getSport, [uid])
        this._preferencesStore.save('BETTING', 'FAVORITE_OFFER_FAMILY_ARG_' + this._contextStore.getSport, [arg])
      }

      setTimeout(() => {
        selectionPaneStore().updateOffers()
      }, 500)
    },

    _buy(formule) {
      if (!formule) return
      switch (formule) {
        case 'BASIC':
          if (contextStore().isLicensed()) {
            message.info('Tu as déjà un abonnement en cours pour cette formule.')
            return
          }
          break
        case 'FULL':
          if (contextStore().isLicensed() && contextStore().getLicenseType() === 'FULL') {
            message.info('Tu as déjà un abonnement en cours pour cette formule.')
            return
          }
          break
      }

      modalStore().openModal({
        uid: 'paypalModal', payload: {
          formule: formule
        }
      })
    }
  },

  computed: {
    _contextStore() {
      return contextStore();
    },
    _preferencesStore() {
      return preferencesStore();
    },
    _leagueStore() {
      return leagueStore();
    },
    _arjelOnly() {
      return selectionPaneStore().arjelOnly
    },
    _canLoadMore(){
      return this.displayedPage * 20 < this._gameAnalysedCount
    },
    _selectedDateInterval() {
      return this.dateIntervalChoices.find(c => c.code === selectionPaneStore().selectedDateInterval);
    },
    _selectedDateIntervalLabel() {
      if (!this._selectedDateInterval) return ''
      return this._selectedDateInterval.shortLabel
    },
    _nextDateInterval() {
      let index = this.dateIntervalChoices.findIndex(c => c.code === selectionPaneStore().selectedDateInterval);
      if (index === -1) return null
      if (index === this.dateIntervalChoices.length - 1) return null
      return this.dateIntervalChoices[index + 1]
    },
    _gameAnalysedCount() {
      return selectionPaneStore().gameAnalyzedCount
    },
    _resultsCount() {
      return selectionPaneStore().results.length
    },
    _filterActive() {
      if (this.filter.length > 2) return this.filter
      return null
    },
    _filterActiveMessage() {
      if (!this._filterActive) return ""
      return 'Aucun résultat avec la recherche "' + this._filterActive + '"'
    },
    _notAllowedLowLevel() {
      const all = selectionPaneStore().notAllowedResultsCount
      if (!all) return null
      if (all['BASIC']) {
        return 'BASIC'
      }
      if (all['FULL']) {
        return 'FULL'
      }
      return null
    },
    _notAllowedResultsCountPRO() {
      if (!this._notAllowedLowLevel) return 0
      const all = selectionPaneStore().notAllowedResultsCount
      return all['BASIC'] ? all['BASIC'] : 0
    },
    _notAllowedResultsCountULTIMATE() {
      if (!this._notAllowedLowLevel) return 0
      const all = selectionPaneStore().notAllowedResultsCount
      return all['FULL'] ? all['FULL'] : 0
    },
    _notAllowedResultsMessage() {
      if (!this._notAllowedLowLevel) return null
      const total = this._notAllowedResultsCountPRO + this._notAllowedResultsCountULTIMATE
      return total + ' matchs réservés aux abonnés.'
    },
    _notAllowedLeaguesPRO() {
      if (!this._notAllowedLowLevel) return null
      const all = selectionPaneStore().notAllowedLeagues
      return all['BASIC']
    },
    _notAllowedLeaguesULTIMATE() {
      if (!this._notAllowedLowLevel) return null
      const all = selectionPaneStore().notAllowedLeagues
      return all['FULL']
    },
    _isRunningSelection() {
      return selectionPaneStore().isRunningSelection
    },
    _gameAnalysedCountMessage() {
      let total = this._gameAnalysedCount
      if (!total) return "Aucun match trouvé"

      let results = selectionPaneStore().results.length
      if (!results === null) return `0 résultat / ${total} matchs`
      if (results === 1) return `1 résultat / ${total} matchs`
      return `${results} résultats / ${total} matchs`
    },
    _results() {
      return selectionPaneStore().results
    },
    _groupedResults() {

      // group by leagueUID
      const critereRegroupement = preferencesStore().getFirstValue('SELECTION', 'CRITERE_REGROUPEMENT');

      let favGamesUIDs = preferencesStore().getValues('GAME', 'FAVORITE_GAME')
      if (!favGamesUIDs) favGamesUIDs = []

      let notebookGamesUIDs = notebookStore().getItems()
      if (!notebookGamesUIDs) notebookGamesUIDs = []

      let results = [...this._results];
      const notebookGamesInResults = results.filter(r => r.gameUID && notebookGamesUIDs.includes(r.gameUID))
      const gavGamesInResults = results.filter(r => r.gameUID && favGamesUIDs.includes(r.gameUID.toString()))
      const otherGamesInResults = results.filter(r => !favGamesUIDs.includes(r.gameUID.toString()) && !notebookGamesUIDs.includes(r.gameUID))

      const completeGroupedBy = otherGamesInResults.reduce((acc, item) => {

        let regroupementKey
        switch (critereRegroupement) {
          case 'DATETIME':
            regroupementKey = dateService.format(item.gameDatetime, 'DD/MM HH:mm')
            break
          case 'NONE':
            regroupementKey = 'NONE'
            break
          case 'LEAGUE':
          default:
            regroupementKey = item.leagueUID
            break
        }

        if (!acc[regroupementKey]) {
          acc[regroupementKey] = []
        }
        acc[regroupementKey].push(item)
        return acc
      }, {})

      Object.keys(completeGroupedBy).forEach(key => {

        let area = null
        let label = null
        let leagueUID = null
        switch (critereRegroupement) {
          case 'DATETIME':
            label = key
            break
          case 'NONE':
            label = 'Tous'
            break
          case 'LEAGUE':
          default:
            let league = this._leagueStore.get(key)
            if (!league) break

            label = league.name + (league.currentSeason ? ' - ' + league.currentSeason : '')
            area = league.area
            leagueUID = league.uid
            break
        }

        completeGroupedBy[key] = {
          area: area,
          leagueUID: leagueUID,
          label: label,
          items: completeGroupedBy[key]
        }
      })

      // tri des items
      const critereTri = preferencesStore().getFirstValue('SELECTION', 'CRITERE_TRI')
      let favGames = preferencesStore().getValues('GAME', 'FAVORITE_GAME')
      if (!favGames) favGames = []

      Object.keys(completeGroupedBy).forEach(key => {
        if (!critereTri || critereTri === 'NOTE') {
          completeGroupedBy[key].items.sort(function (a, b) {

            // On tri sur le fait que le match soit en favoris
            const isFavA = favGames.includes(a.gameUID && a.gameUID.toString())
            const isFavB = favGames.includes(b.gameUID && b.gameUID.toString())

            if (isFavA && !isFavB) return -1
            if (!isFavA && isFavB) return 1

            return b.matchingScore - a.matchingScore;
          })
        }

        if (critereTri === 'DATETIME') {
          completeGroupedBy[key].items.sort(function (a, b) {

            // On tri sur le fait que le match soit en favoris
            const isFavA = favGames.includes(a.gameUID.toString())
            const isFavB = favGames.includes(b.gameUID.toString())

            if (isFavA && !isFavB) return -1
            if (!isFavA && isFavB) return 1

            return dateService.compare(a.gameDatetime, b.gameDatetime);
          })
        }
      })

      // add fav games group
      if (notebookGamesInResults.length > 0) {
        completeGroupedBy['NOTEBOOK'] = {
          area: null,
          leagueUID: null,
          label: 'Notebook',
          items: notebookGamesInResults
        }
      }

      // add fav games group
      if (gavGamesInResults.length > 0) {
        completeGroupedBy['FAVORITES'] = {
          area: null,
          leagueUID: null,
          label: 'Favoris',
          items: gavGamesInResults
        }
      }

      // On ne va garder que this.displayedPage * 20 resultats
      let canBeAddedCount = this.displayedPage * 20
      const displayedGroupedBy = {}
      Object.keys(completeGroupedBy).forEach(key => {
        if (canBeAddedCount <= 0) return
        const itemsToDisplay = completeGroupedBy[key].items.slice(0, canBeAddedCount)
        displayedGroupedBy[key] = {
          area: completeGroupedBy[key].area,
          leagueUID: completeGroupedBy[key].leagueUID,
          label: completeGroupedBy[key].label,
          items: itemsToDisplay
        }
        canBeAddedCount -= itemsToDisplay.length
      })

      return displayedGroupedBy
    },
    _orderedGroupementKeys() {
      const critereRegroupement = preferencesStore().getFirstValue('SELECTION', 'CRITERE_REGROUPEMENT');
      if (!critereRegroupement || critereRegroupement === 'LEAGUE') {
        return ['NOTEBOOK', 'FAVORITES'].concat(this._orderedLeaguesUIDs)
      }
      if (critereRegroupement === 'DATETIME') {
        return ['NOTEBOOK', 'FAVORITES'].concat(Object.keys(this._groupedResults).sort())
      }

      if (critereRegroupement === 'NONE') return ['NOTEBOOK', 'FAVORITES', 'NONE']

      // si le critère n'est pas défini, on utilise le paramètre par défaut
      return Object.keys(this._groupedResults)
    },
    _orderedLeaguesUIDs() {
      // On récupère les ligues favorites
      const favoriteLeagues = this._preferencesStore.getValues('LEAGUE', 'FAVORITE_LEAGUES_' + this._contextStore.getSport);
      if (!favoriteLeagues || !favoriteLeagues.length) return Object.keys(this._groupedResults)
      const favoriteLeaguesUIDs = favoriteLeagues.map(l => Number(l))

      const favUIDs = []
      const otherUIDs = []
      Object.keys(this._groupedResults).forEach(leagueUID => {
        if (favoriteLeaguesUIDs.includes(Number(leagueUID))) {
          favUIDs.push(leagueUID)
        } else {
          otherUIDs.push(leagueUID)
        }
      })

      // On place les ligues favorites en premier
      return favUIDs.concat(otherUIDs)
    },
    _critereRegroupementLabel() {
      const critereRegroupement = preferencesStore().getFirstValue('SELECTION', 'CRITERE_REGROUPEMENT');
      if (!critereRegroupement || critereRegroupement === 'LEAGUE') return 'Compét.'
      if (critereRegroupement === 'DATETIME') return 'Date'
      if (critereRegroupement === 'NONE') return 'Aucun'

      // si le critère n'est pas défini, on utilise le paramètre par défaut
      return 'Compét.'
    },
    _critereTriLabel() {
      const critereTri = preferencesStore().getFirstValue('SELECTION', 'CRITERE_TRI');
      if (critereTri === 'NOTE') return 'Note'
      if (critereTri === 'DATETIME') return 'Date'

      // si le critère n'est pas défini, on utilise le paramètre par défaut
      return 'Note'
    },

    _sportOfferFamilies() {
      let offerFamilyDefinitionsBySport = offerStore().getOfferFamilyDefinitionsBySport(this._contextStore.getSport);
      if (!offerFamilyDefinitionsBySport) return []
      return offerFamilyDefinitionsBySport.items
    },

    _sportOfferFamilyChoices() {
      const results = []
      if (!this._sportOfferFamilies) {
        return results
      }

      this._sportOfferFamilies.forEach(f => {

        // On liste les arguments possibles
        let args = []
        f.choices.forEach(c => {
          c.arguments.forEach(a => {
            args.push(a)
          })
        })
        // remove duplicates
        args = args.filter((v, i, a) => a.indexOf(v) === i);
        // remove null
        args = args.filter(a => a !== null)
        if (args.length === 0) {
          args = null
        }

        results.push({
          label: offerStore().getOfferFamilyDefinitionLabel(f.code),
          value: f.fixedUID,
          args: args
        })
      })
      return results
    },

    _offerTypeLabel() {
      let familyFixedUID = selectionPaneStore().getOfferFamilyFixedUID()
      if (!familyFixedUID) {
        familyFixedUID = this._preferencesStore.getFirstValue('BETTING', 'FAVORITE_OFFER_FAMILY_' + this._contextStore.getSport)
        if (familyFixedUID) {
          const favArg = this._preferencesStore.getFirstValue('BETTING', 'FAVORITE_OFFER_FAMILY_ARG_' + this._contextStore.getSport)
          selectionPaneStore().setOfferArgument(favArg)
        }
      }
      if (!familyFixedUID) {
        let firstValue = this._sportOfferFamilyChoices[0];
        if (firstValue) {
          selectionPaneStore().setOfferFamilyFixedUID(firstValue.value)
          selectionPaneStore().setOfferArgument(firstValue.args ? firstValue.args[0] : null)
          familyFixedUID = firstValue.value
        }
      }
      if (!familyFixedUID) {
        return 'Type offre'
      }
      if (!this._sportOfferFamilies) {
        return 'Type offre'
      }

      const family = this._sportOfferFamilies.find(f => f.fixedUID === parseInt(familyFixedUID))
      if (!family) {
        return 'Type offre'
      }

      let arg = selectionPaneStore().getOfferArgument()
      return offerStore().getOfferFamilyDefinitionLabel(family.code) + (arg ? ' ' + arg : '')
    },

    _leagueGroups() {
      let groups = leagueGroupsStore().getBySport(contextStore().getSport);
      groups.sort((a, b) => a.name.localeCompare(b.name))
      return groups
    },

    _selectedLeaguesLabel() {
      let label = selectionPaneStore().selectedLeagueLabel;
      if (!label) return 'Toutes'
      return label
    }
  },
  watch: {
    filter: function (val) {
      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
        selectionPaneStore().filter = val
        selectionPaneStore().runSelection()
      }, 300);
    }
  }
}
</script>
<style lang="less">
@import "@/assets/styles/variables.less";

#selection-pane-wrapper {
  #selection-pane {
    margin: @padding-small 0 @padding-small @padding-small;
    border-radius: @radius-regular;
    background: rgb(94,53,43);
    background: linear-gradient(122deg, rgba(94,53,43,1) 0%, rgba(94,53,43,0.95) 3%, rgba(37,37,42,0.85) 21%, rgba(37,37,42,0.75) 100%);
    height: calc(100dvh - @toolbar-regular);
    width: 400px !important;
    min-width: 400px !important;

    .selection-results {
      height: calc(100dvh - (@toolbar-regular * 3));
      overflow-y: scroll;
      scrollbar-width: none;
    }
  }
}

.mobile {
  #selection-pane-wrapper {
    #selection-pane {
      width: calc(100vw - @padding-small * 2) !important;
      min-width: calc(100vw - @padding-small * 2) !important;
    }
  }
}

.not-allowed-results {
  background: @background-color;
  border-radius: @radius-regular;

  margin: @padding-small;
  color: @text-color;

  .not-allowed-leagues {
    border-top: 1px solid @stroke-color;
    border-bottom: 1px solid @stroke-color;
    text-align: left;
    padding: @padding-small @padding-regular;

    .not-allowed-league {
      display: inline-block;
    }
  }
}

.selection-pane-groups{
  margin: @padding-large 0;

  &:last-child{
    margin-bottom: 0;
  }
}

</style>