// stores/counter.js
import {defineStore} from 'pinia'
import {preferencesStore} from "@/stores/Preferences.store";
import dateService from "@/services/date.service";
import {perspectiveStore} from "@/stores/Perspective.store";
import {contextStore} from "@/stores/Context.store";

export const statsRouteStore = defineStore('teamRouteStore', {
    state: () => {
        return {

            // Perspectives
            selectedPerspectiveUID: null,

            // Filtres
            selectedDateInterval: 'TODAY',
            selectedLeagueLabel: 'Toutes',
            selectedLeagueUIDs: [],
            filter: '',

            // Focus context
            overContext: null,  // Represente le contexte de l'element survolé
            overContextPin: false
        }
    },
    actions: {
        init() {
            statsRouteStore().selectFirstPerspective()

        },
        selectFirstPerspective() {
            // On prend la premiere perspective
            const ps = perspectiveStore()
            const allPerspectives = ps.getBySportAndType(contextStore().getSport, 'TEAM')
            if (allPerspectives.length > 0) {
                this.selectedPerspectiveUID = allPerspectives[0].uid
                preferencesStore().save('DEFAULT_PERSPECTIVE', 'TEAM_PERSPECTIVE', [this.selectedPerspectiveUID])
            }
        },
        updateWidgets() {
            window.emitter.emit('stats-route:widgets-need-to-refresh')
        },
        setSelectedPerspectiveUID(selectedPerspectiveUID) {
            this.selectedPerspectiveUID = selectedPerspectiveUID
        },
        setFilter(filter) {
            this.filter = filter
            statsRouteStore().updateWidgets()
        },
        setSelectedDateInterval(selectedDateInterval) {
            this.selectedDateInterval = selectedDateInterval
            statsRouteStore().updateWidgets()
        },
        setSelectedLeagueLabel(selectedLeagueLabel) {
            this.selectedLeagueLabel = selectedLeagueLabel
        },
        setSelectedLeagueUIDs(selectedLeagueUIDs) {
            this.selectedLeagueUIDs = selectedLeagueUIDs
            statsRouteStore().updateWidgets()
        },
        setOverContext(overContext, pin) {
            if (!this.overContext) {
                this.overContext = overContext
                this.overContextPin = pin
                return
            }

            if (pin === true) {   // Si on veut garder le contexte actuel
                if (this.overContextPin && this.overContext.gameUID === overContext.gameUID) {
                    this.overContext = null
                    this.overContextPin = false
                    return;
                }
                this.overContext = overContext
                this.overContextPin = true
            } else {
                if (this.overContextPin) {
                    return
                }
                this.overContext = overContext
            }
        },
        onSportChange() {
            this.selectFirstPerspective()
            setTimeout(() => {
                window.emitter.emit('stats-route:widgets-need-to-refresh')
            }, 250)
        }
    },
    getters: {
        buildBaseWidgetPayload: (state) => () => {

            const dateInterval = dateService.buildDateInterval(state.selectedDateInterval)

            // gestion ARJEL
            const arjelOnly = preferencesStore().getBoolean('BETTING', 'ARJEL_ONLY', false)

            return {
                leagueUIDs: state.selectedLeagueUIDs,
                filter: state.filter,
                arjelOnly: arjelOnly,
                dateInterval: dateInterval
            }
        },

        getOverContext: (state) => {
            return state.overContext
        },

        getSelectedPerspectiveUID: (state) => () => {
            return state.selectedPerspectiveUID
        }
    }
})