<script>
import {modalStore} from "../../../../stores/Modal.store";
import {bankrollStore} from "../../../../stores/Bankroll.store";
import {contextStore} from "../../../../stores/Context.store";
import {sportStore} from "@/stores/Sport.store";
import {bankrollPaneStore} from "../panes/bankroll/BankrollPane.store";

export default {
  name: "CreateTicketModal",
  data() {
    return {
      bets: [
        {
          label: "",
          sport: 'FOOTBALL',
          odd: 1.01,
        }
      ],
      stakeRelative: 1.0,
      stakeAbsolute: 0.0,
      stakeIsRelative: false,
      isFreeBet: false,

      form: {
        stake: 1.0,
      }
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'createTicketModal';
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _sports() {
      return sportStore().getAll
    },
    _bet() {
      return this.bets[0]
    },
    _activeBankrollUID() {
      return bankrollPaneStore().getBankrollActiveUID()
    },
    _bankrollBalance() {
      return bankrollStore().getActiveBankrollBalance(this._activeBankrollUID)
    },
    _isStakeRelativeValue() {
      return this.stakeRelative > 0 && this.stakeRelative <= 100
    },
    _isOddDefined() {
      return this._bet.odd >= 1.0
    },
    _isLabelDefined() {
      return this._bet.label.trim().length > 0
    },
    _canAddTicket() {
      return this._isLabelDefined && this._isOddDefined && this._isStakeRelativeValue
    },

    _alternativeStake() {
      return this.stakeIsRelative ? this.stakeAbsolute : this.stakeRelative
    }
  },
  methods: {
    _onConfirm() {
      this._onApply()
      this._onCancel()
    },
    _onApply() {
      if (!this._canAddTicket) return
      const payload = {
        bankrollUID: this._activeBankrollUID,
        bets: this.bets,
        stake: this.form.stake,
        stakeIsRelative: this.stakeIsRelative,
        isFreeBet: this.isFreeBet
      }
      bankrollStore().addTicket(payload)
    },
    _onCancel() {
      modalStore().closeModal()
    },
    _switchFreeBet() {
      this.isFreeBet = !this.isFreeBet
    },
    _changeSport(sport) {
      this._bet.sport = sport.code
    },
    _changeStakeRelative() {
      this.stakeIsRelative = !this.stakeIsRelative

      if (this.stakeIsRelative) {
        this.form.stake = this.stakeRelative
      } else {
        this.form.stake = this.stakeAbsolute
      }
      this._onStakeChange()
    },
    _onStakeChange() {
      // if more than 2 decimals, round to 2 decimals
      const moreThanTwoDecimals = this.form.stake.toString().split(".")[1]?.length > 2
      if (moreThanTwoDecimals) {
        this.form.stake = parseFloat(this.form.stake.toFixed(2))
      }

      if (this.stakeIsRelative) {
        this.stakeRelative = this.form.stake
        this.stakeAbsolute = (this._bankrollBalance * this.stakeRelative / 100.0).toFixed(2)
      } else {
        this.stakeAbsolute = this.form.stake
        this.stakeRelative = (this.stakeAbsolute / this._bankrollBalance * 100.0).toFixed(2)
      }
    }
  },
  watch: {
    _isVisible: function (val) {
      this.stakeAbsolute = this._bankrollBalance * this.stakeRelative / 100.0

      // give focus to the input with ref="bet-label"
      this.$nextTick(() => {
        const input = this.$refs['bet-label']
        if (input) {
          input.focus()
        }
      })
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="400" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Nouveau ticket
    </header>

    <div class="content py-2">
      <fieldset class="flex no-border">
        <legend class="pl-1 flex-grow">Libellé</legend>
        <div class="flex-grow px-2">
          <input ref="bet-label" type="text" v-model="_bet.label" placeholder="Libellé du pari" style="width: 100%">
        </div>
      </fieldset>

      <div class="mt-2 flex">
        <fieldset class="flex-grow no-border">
          <legend class="ml-1 m-0">Sport</legend>
          <div class="ml-2">
            <button :class="{'square':true, 'primary selected' : _bet.sport === sport.code}" v-for="sport in _sports"
                    @click="_changeSport(sport)">
              <font-awesome-icon :icon="sport.icon"/>
            </button>
          </div>
        </fieldset>
        <fieldset class="px-2 no-border text-right">
          <legend>Côte</legend>
          <input type="number" v-model="_bet.odd" step='0.01' placeholder="Côte" style="text-align: end; width: 64px">
        </fieldset>
      </div>
      <hr class="my-1"/>
      <div class="flex">
        <fieldset class="flex no-border ml-1">
          <legend>Freebet</legend>
          <button :class="{'small ml-1': true, 'primary': isFreeBet, 'info': !isFreeBet}" @click="_switchFreeBet">
            {{ isFreeBet ? 'Oui' : 'Non' }}
          </button>
        </fieldset>
        <div class="flex-grow"/>
        <fieldset class="flex px-2 no-border  text-right">
          <legend>Mise ({{ stakeIsRelative ? '%' : '€' }})</legend>
          <input type="text" :placeholder="_alternativeStake + (stakeIsRelative ? '€' : '%')" style="width: 64px"
                 step='0.01' class="mr-1 no-border text-right" disabled>
          <button class="info small mr-1" @click="_changeStakeRelative">
            {{ stakeIsRelative ? '%' : '€' }}
          </button>
          <input type="number" v-model="form.stake" step='0.01' placeholder="0.00" style="width: 64px"
                 class=" text-right" @input="_onStakeChange">
        </fieldset>
      </div>

    </div>


    <template #footer>
      <div class="my-2 mx-1">
        <button class="mr-2 primary" @click="_onConfirm" :disabled="!_canAddTicket">Ajouter ticket</button>
        <button class="" @click="_onCancel">Annuler</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>