<script>
import {webSocketStore} from "@/stores/WebSocket.store";
import EditSelectionConditionModal from "@/components/routes/selection/modals/EditSelectionConditionModal.vue";
import EditSelectionModal from "@/components/routes/selection/modals/EditSelectionModal.vue";
import PaypalModal from "@/components/common/modal/paypal/PaypalModal.vue";
import GameDetailsModal from "@/components/common/modal/game/GameDetailsModal.vue";
import StatSelectorModal from "@/components/common/modal/StatSelector.Modal.vue";
import GameModal from "@/components/common/modal/game/GameModal.vue";
import LeagueGroupsModal from "@/components/common/modal/selection_league_modal/LeagueGroupsModal.vue";
import WidgetSettingsModal from "@/components/common/modal/widget_settings/WidgetSettingsModal.vue";
import PerspectiveSettingsModal from "@/components/common/modal/perspective_settings/PerspectiveSettingsModal.vue";
import WidgetParameterSelectorModal from "@/components/common/modal/WidgetParameterSelector.Modal.vue";
import UserPreferencesModal from "@/components/common/modal/user_preferences/UserPreferencesModal.vue";
import LeagueGroupModal from "@/components/common/modal/selection_league_modal/LeagueGroupModal.vue";
import SerieStatsModal from "@/components/routes/stats/modals/SerieStatsModal.vue";
import FrequencyStatsModal from "@/components/routes/stats/modals/FrequencyStatsModal.vue";
import RawStatsModal from "@/components/routes/stats/modals/RawStatsModal.vue";
import TeamModal from "@/components/common/modal/team/TeamModal.vue";
import SearchModal from "@/components/common/modal/search/SearchModal.vue";
import CalculatorModal from "@/components/common/modal/calculator/CalculatorModal.vue";
import EditBankrollModal from "@/components/routes/bankroll/modals/EditBankrollModal.vue";
import AllMonitoringTicketsModal from "@/components/routes/selection/modals/AllMonitoringTicketsModal.vue";
import SelectionLeagueModal from "@/components/common/modal/selection_league_modal/SelectionLeagueModal.vue";
import SelectionMonitoringListModal
  from "@/components/routes/selection/modals/selection_monitoring_list/SelectionMonitoringListModal.vue";
import StatisticModal from "@/components/common/modal/statistic/StatisticModal.vue";
import LeagueModal from "@/components/common/modal/league/LeagueModal.vue";
import LicenceLeagueModal from "@/components/common/modal/LicenceLeagueModal.vue";
import OfferModal from "@/components/common/modal/offer/OfferModal.vue";
import CreateSelectionModal from "@/components/routes/selection/modals/CreateSelectionModal.vue";
import SelectionsModal from "@/components/routes/selection/modals/SelectionsModal.vue";
import WidgetStoreModal from "@/components/routes/selection/modals/widget_store/WidgetStoreModal.vue";
import CreateMonitoringModal from "@/components/routes/selection/modals/CreateMonitoringModal.vue";
import TicketGeneratorModal from "@/components/routes/selection/modals/ticket_generator/TicketGeneratorModal.vue";
import PaperTicketModal from "@/components/routes/selection/modals/ticket_generator/PaperTicketModal.vue";
import UserReferringCodeFormModal from "@/components/common/modal/user_preferences/UserReferringCodeFormModal.vue";
import BookMedicalModal from "@/components/common/modal/notebook/NotebookModal.vue";

export default {
  name: "App",
  components: {
    BookMedicalModal,
    PaperTicketModal,
    TicketGeneratorModal,
    CreateMonitoringModal,
    WidgetStoreModal,
    SelectionsModal,
    CreateSelectionModal,
    OfferModal,
    LicenceLeagueModal,
    LeagueModal,
    StatisticModal,
    SelectionMonitoringListModal,
    SelectionLeagueModal,
    AllMonitoringTicketsModal,
    EditBankrollModal,
    CalculatorModal,
    SearchModal,
    TeamModal,
    RawStatsModal,
    FrequencyStatsModal,
    SerieStatsModal,
    LeagueGroupModal,
    UserPreferencesModal,
    UserReferringCodeFormModal,
    WidgetParameterSelectorModal,
    PerspectiveSettingsModal,
    WidgetSettingsModal,
    LeagueGroupsModal,
    GameModal,
    StatSelectorModal,
    GameDetailsModal,
    PaypalModal,
    EditSelectionModal, EditSelectionConditionModal},

  mounted() {
    webSocketStore().connect();
  }
}
</script>

<template>

  <div class="route-wrapper">
    <div class="bg-dots"/>
    <router-view/>
  </div>


  <!-- Modals -->
  <user-preferences-modal/>
  <calculator-modal/>
  <stat-selector-modal/>
  <widget-parameter-selector-modal/>
  <widget-settings-modal/>
  <perspective-settings-modal/>
  <search-modal/>
  <game-modal/>
  <team-modal/>
  <paypal-modal/>
  <frequency-stats-modal/>
  <raw-stats-modal/>
  <serie-stats-modal/>
  <game-details-modal/>
  <league-groups-modal/>
  <league-group-modal/>
  <edit-selection-modal/>
  <edit-selection-condition-modal/>
  <league-modal/>
  <statistic-modal/>
  <create-selection-modal/>
  <create-monitoring-modal/>
  <selections-modal/>
  <selection-league-modal/>
  <offer-modal/>
  <widget-store-modal/>
  <selection-monitoring-list-modal/>
  <licence-league-modal/>
  <edit-bankroll-modal/>
  <all-monitoring-tickets-modal/>
  <ticket-generator-modal/>
  <paper-ticket-modal/>
  <user-referring-code-form-modal/>
  <book-medical-modal/>
</template>

<style scoped lang="less">

</style>