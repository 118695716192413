<script>
import {gameStore} from "@/stores/Game.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {teamStore} from "../../stores/Team.store";

export default {
  name: "GameResultCircle",
  components: {FontAwesomeIcon},
  props: {
    gameUid: {
      required: true
    },
    teamUid: {
      required: true
    },
  },
  computed: {
    _gameStore() {
      return gameStore();
    },
    _game() {
      return this._gameStore.get(this.gameUid)
    },
    _isHome() {
      if (!this._game) return false
      return this._game.homeTeamUID === this.teamUid
    },
    _isAway() {
      if (!this._game) return false
      return this._game.awayTeamUID === this.teamUid
    },
    _result() {
      const result = gameStore().getResultFor(this._game, this._isHome ? 'HOME' : 'AWAY')
      if (!result) return '?'
      if (result === 0) return 'N'
      if (result < 0) return 'D'
      if (result > 0) return 'V'
    },
    _icon() {
      if (this._isHome) return 'fa-solid fa-house'
      if (this._isAway) return 'fa-solid fa-plane'
      return 'fa-solid fa-question'
    },
    _gameScore() {
      if (!this._game) return '/'
      if (this._game.scoreFtHome === null || this._game.scoreFtAway === null) return 'A venir'
      return this._game.scoreFtHome + '/' + this._game.scoreFtAway
    },
    _againstName() {
      if (!this._game) return '?'
      const isHome = this._game.homeTeamUID === this.teamUid
      const isAway = this._game.awayTeamUID === this.teamUid
      if (!isHome && !isAway) return '?'

      let againstTeam = null
      if (isHome) {
        againstTeam = teamStore().get(this._game.awayTeamUID)
      }
      if (isAway) {
        againstTeam = teamStore().get(this._game.homeTeamUID)
      }

      if (!againstTeam) return '?'
      return againstTeam.name
    },
    _tooltipTitle() {
      return this._gameScore + ' vs ' + this._againstName
    },
    _buildCssClasses() {
      return {
        'game-result-circle': true,
        'unknown': this._result === '?',
        'defeat': this._result === 'D',
        'victory': this._result === 'V',
        'draw': this._result === 'N',
      }
    },
  }
}
</script>

<template>
  <a-tooltip :title="_tooltipTitle">
    <div :class="_buildCssClasses">
      <font-awesome-icon :icon="_icon"/>
    </div>
  </a-tooltip>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.game-result-circle {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 8pt;

  &.unknown, &.draw {
    background-color: @neutral-color;
    color: white !important;
  }

  &.defeat {
    background-color: @negative-color;
    color: white !important;
  }

  &.victory {
    background-color: @positive-color;
    color: white !important;
  }
}
</style>