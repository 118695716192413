// stores/counter.js
import {defineStore} from 'pinia'
import axiosService from "../services/axios.service";
import {bankrollStore} from "@/stores/Bankroll.store";

export const monitoringStore = defineStore('monitoringStore', {
    state: () => {
        return {
            items: null
        }
    },
    actions: {

        create(selectionUID, bankrollUID, bookmakerUID, addBetForm, callback = null) {
            const payload = {
                selectionUID: selectionUID,
                bankrollUID: bankrollUID,
                bookmakerUID: bookmakerUID,
                keepBetStrategy: 'ALWAYS'
            }
            axiosService.post('/mon', payload).then(response => {
                const ms = monitoringStore();
                let item = response.data.result;
                ms.add(item)

                const firstTemplate = item.templates[0]

                bankrollStore().fetchBankrollByUID(item.bankrollUID)

                ms.createMonitoringTicketTemplateBet( {
                    monitoringTicketTemplateUID: firstTemplate.uid,
                    offerFamilyFixedUID: addBetForm.offerFamilyFixedUID,
                    offerArgument: addBetForm.offerArgument,
                    offerFamilyChoiceFixedUID: addBetForm.selectedOfferFamilyChoiceFixedUID
                }, () => {
                    if (callback) callback()
                })
            });
        },

        createMonitoringTicketTemplateBet(params, callback = null) {
            axiosService.post('/mon/ticket-template/bet', params).then(response => {
                const ms = monitoringStore();
                let ticketTemplate = ms.getTicketTemplateByUID(params.monitoringTicketTemplateUID);
                ticketTemplate.bets.push(response.data.result)
            }).catch(error => {
                console.error('monitoringStore.createMonitoringTicketTemplateBet', error)
            }).finally(() => {
                if (callback) callback()
            })
        },

        deleteMonitoringTicketTemplateBet(uid, callback = null) {
            axiosService.delete('/mon/ticket-template/bet?uid='+uid).then(response => {
                const ms = monitoringStore();
                let ticketTemplate = ms.getTicketTemplateByBetUID(uid);
                ticketTemplate.bets = ticketTemplate.bets.filter(bet => bet.uid !== uid)
            }).catch(error => {
                console.error('monitoringStore.deleteMonitoringTicketTemplateBet', error)
            }).finally(() => {
                if (callback) callback()
            })
        },

        delete(uid, callback = null) {
            axiosService.delete('/mon?uid='+uid).then(response => {
                const ms = monitoringStore();
                ms.items = ms.items.filter(item => item.uid !== uid)
            }).catch(error => {
                console.error('monitoringStore.delete', error)
            }).finally(() => {
                if (callback) callback()
            })
        },

        /**
         * Permet l'initialisation des bankrolls
         * @param callback
         */
        fetchAll(callback = null) {
            if (this.items !== null) {
                if (callback) callback()
                return
            }
            this.items = []

            axiosService.get('/mon').then(response => {
                this.items = this.items.concat(response.data.results)

            }).catch(error => {
                console.error('monitoringStore.fetchAll', error)
            }).finally(() => {
                if (callback) callback()
            })
        },

        add(item) {
            if(!this.items){
                this.items = []
            }
            // si item déjà présent, on ne l'ajoute pas
            if (this.items.find(i => i.uid === item.uid)) {
                return
            }
            this.items.push(item)
        },

        addAll(items) {
            items.forEach(item => {
                this.add(item)
            })
        }
    },
    getters: {
        get: (state) => (uid) => {

            if (state.items === null) {
                return null
            }

            let item = state.items.find(item => item.uid === uid);
            if (item) {
                return item
            }

            return null
        },

        getByBankrollUID: (state) => (bankrollUID) => {
            if (state.items === null) {
                return []
            }

            let items = state.items.filter(item => item.bankrollUID === bankrollUID);
            if (items) {
                return items
            }

            return []
        },

        getTicketTemplateByUID: (state) => (uid) => {
            if (state.items === null) {
                return null
            }

            let result = null
            state.items.forEach(item => {
                if(item.templates !== null) {
                    item.templates.forEach(template => {
                      if(template.uid === uid) {
                        result = template
                      }
                    })
                }
            })
            return result
        },

        getTicketTemplateByBetUID: (state) => (betUID) => {
            if (state.items === null) {
                return null
            }

            let result = null
            state.items.forEach(item => {
                if(item.templates !== null) {
                    item.templates.forEach(template => {
                        if(template.bets !== null) {
                            template.bets.forEach(bet => {
                                if(bet.uid === betUID) {
                                    result = template
                                }
                            })
                        }
                    })
                }
            })
            return result
        },

        getBySelectionUID: (state) => (selectionUID) => {
            if (state.items === null) {
                return []
            }

            let items = state.items.filter(item => item.selectionUID === selectionUID);
            if (items) {
                return items
            }

            return []
        }
    }
})