<script>
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {notebookStore} from "@/stores/NotebookStore";
import SimpleGameRow from "@/components/routes/selection/panes/selection/items/tabs/SimpleGameRow.vue";
import NotebookGameRow from "@/components/common/modal/notebook/NotebookGameRow.vue";
import ChatMessage from "@/components/common/modal/notebook/Note.vue";
import {noteStore} from "@/stores/Note.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "NotebookModal",
  components: {FontAwesomeIcon, ChatMessage, NotebookGameRow, SimpleGameRow},
  data() {
    return {
      selectedGameUID: null,
      messages: null,
      page: 0,

      // formulaire de création de message
      form: {
        content: '',
        timeout: null,
        saved: true
      }

    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal
      if (!visibleModal) return false
      return visibleModal.uid === 'notebookModal'
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _isAuthenticated() {
      return contextStore().isAuthenticated()
    },
    _gameUIDs() {
      return notebookStore().getItems()
    },
    _note() {
      return noteStore().getByGameUID(this.selectedGameUID)
    },
    _noteLength() {
      if (!this.form.content) return 0
      return this.form.content.length
    }
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _setSelectedGameUID(gameUID) {
      this.selectedGameUID = gameUID
    },
    _updateNote() {
      if (!this._isAuthenticated) return

      // Check if content is not empty and more than 512 characters
      if (!this.form.content || this.form.content.length === 0) return

      if(this.form.content.length > 1000){
        this.form.content = this.form.content.substring(0, 1000)
      }

      this.form.saved = false
      clearTimeout(this.form.timeout)
      this.form.timeout = setTimeout(this._updateNoteNow, 500)
    },
    _updateNoteNow() {
      if (!this._isAuthenticated) return
      noteStore().update(this.selectedGameUID, this.form.content)
      this.form.saved = true
    },
    _removeFromNotebook() {
      notebookStore().remove(this.selectedGameUID)
      // Close modal if no more games in notebook
      if (this._gameUIDs.length === 0) {
        modalStore().closeModal()
      }else{
        this.selectedGameUID = this._gameUIDs[0]
      }
    }
  },
  watch: {
    _isVisible() {
      if (this._isVisible) {
        let hasGames = this._gameUIDs.length > 0;
        let hasSelectedGame = this.selectedGameUID;
        let isSelectedGameUIDValid = this._gameUIDs.includes(this.selectedGameUID);
        if(hasGames && (!isSelectedGameUIDValid || !hasSelectedGame)){
          this.selectedGameUID = this._gameUIDs[0]
        }
      }
    },
    _note(note) {
      if (note) {
        this.form.content = note.content
      }else{
        this.form.content = ''
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :width="850" :open="true" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Notebook
    </header>
    <div class="content flex">
      <div class="notebook-games lg:w-2/5 md:w-1/2 p-2">
        <notebook-game-row @click="_setSelectedGameUID(gameUID)" :selected="selectedGameUID === gameUID"
                           v-for="gameUID in _gameUIDs" :key="gameUID" :gameUID="gameUID" class="mb-2"/>
      </div>
      <div class="notebook-game-notes lg:w-3/5 md:w-1/2 p-2">
        <fieldset class="no-border">
          <legend>Note personnelle ({{_noteLength}}/1000)
            <font-awesome-icon icon="fa-solid fa-spinner" :spin="true" class="primary" v-if="!form.saved"/>
          </legend>
          <textarea v-model="form.content"
                    placeholder="Redige ta note ici pour ne rien oublier de ton analyse" class="post-it"
                    @keyup="_updateNote"/>
        </fieldset>

      </div>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="info" @click="_removeFromNotebook">
          <span>Retirer du Notebook</span>
        </button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.notebook-games {
  background: @background-color-light;
}

textarea.post-it {
  background-color: @background-color-light;
  border: 2px solid @stroke-color;
  border-radius: @radius-regular;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 12pt !important;
  line-height: 1.5;
  resize: none;
  width: 100%;
  height: 200px;
  outline: none;
}
</style>