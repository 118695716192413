<script>
import {selectionStore} from "@/stores/Selection.store";
import {modalStore} from "../../../../stores/Modal.store";

export default {
  name: "CreateSelectionModal",
  data() {
    return {
      name: ""
    }
  },

  computed: {
    _selectionStore() {
      return selectionStore();
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'createSelectionModal';
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.payload;
    },
    _sport() {
      if (!this._payload) return null
      return this._payload.sport
    }
  },
  methods: {
    _onConfirm() {
      if (!this.name) return
      if (!this._sport) return
      this._selectionStore.createSelection(this.name, this._sport, (selection) => {
        modalStore().closeModal()

        modalStore().openModal(
            {
              uid: "editSelectionModal",
              payload: {
                selection: selection,
                sport: selection.sport
              }
            }
        )
      })
    },

    _onCancel() {
      modalStore().closeModal()
    },
  },
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="400" @cancel="_onCancel">
    <header class="ant-modal-header">
      Nouvelle sélection
    </header>

    <div class="p-1">
      <label class="w-1/4 inline-block">Nom</label>
      <input class="w-3/4" type="text" v-model="name" placeholder="Donne un nom à ta selection">
    </div>

    <template #footer>
      <div class="my-2 mx-1 flex">
        <button @click="_onCancel">Annuler</button>
        <div class="flex-grow"/>
        <button class="primary" @click="_onConfirm">Créer la sélection</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>