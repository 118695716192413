// stores/counter.js
import {defineStore} from 'pinia'
import {teamLoaderStore} from "@/stores/Team.Loader.store";
import indexedDBService from "@/services/indexedDB.service";

export const teamStore = defineStore('teamStore', {
    state: () => {
        return {
            items: []
        }
    },
    actions: {
        init() {
            indexedDBService.get('teams').then(response => {
                if(!response) return
                if(!response.stringify) return

                // convert string to array
                const items = JSON.parse(response.stringify)
                this.items = items || []
            })
        },
        append(teams) {
            this.items = this.items.concat(teams)

            // Save items in indexedDB
            indexedDBService.set('teams', this.items).then(response => {
                console.debug('teams saved')
            })
        }
    },
    getters: {

        get: (state) => (uid) => {

            const loader = teamLoaderStore()
            if (loader.isLoading(uid)) {
                return null
            }

            uid = parseInt(uid)

            let team = state.items.find(item => item.uid === uid);
            if (team) {
                return team
            }

            // Si la team n'est pas dans le store, on le charge
            loader.fetchTeamByUID(uid)

            return null
        },

        getArea: (state) => (uid) => {
            const ts = teamStore()
            let team = ts.get(uid);
            if (!team) {
                return null
            }
            return team.area
        },

        getAll: (state) => {
            return state.items
        }
    }
})